import React, { useState, useEffect } from 'react';

const Loader = () => {
  // State to track whether content has finished loading
  const [loaded, setLoaded] = useState(false);

  // Simulating content loading with a useEffect hook
  useEffect(() => {
    // Simulate content loading (replace with actual loading logic)
    const timeout = setTimeout(() => {
      // Set loaded to true after 2 seconds (simulating content loading)
      setLoaded(true);
    }, 2000);

    // Cleanup function
    return () => clearTimeout(timeout);
  }, []);

  // Render the preloader if content is not yet loaded
  if (!loaded) {
    return (
      <div className="preloader">
        <h1 data-text="ImmiGrants Canada" className="preloader-text">
          ImmiGrants Canada
        </h1>
      </div>
    );
  }

  // If content is loaded, return null to render nothing
  return null;
};

export default Loader;
