
import './App.css';
import { Route, Routes } from 'react-router-dom';
import MainContent from './pages/MainContent';
import AboutUs from "./pages/AboutUs";
import Layout from "./Layout";
import Testimonials from './components/Testimonials/Testimonials';
import ServicesSection from './components/ServicesSection/ServicesSection';
import Blog from './pages/Blog';
import Contact from './pages/Contact';
import InternationalStudents from './components/ServicesSection/InternationalStudents.jsx/InternationalStudents';
import ForeignWork from './components/ServicesSection/ForeignWork/ForeignWork';
import PermanentResidence from './components/PermanentResidence/PermanentResidence';

function App() {
 


  return (
   <div className="App">
      <Routes>
        <Route path='/' element={<Layout children={<MainContent />}/> } />
        <Route path='/about' element={<Layout children={<AboutUs />}/> } />
        <Route path='/testimonials' element={<Layout children={<Testimonials />}/> } />
        <Route path='/our-services' element={<Layout children={<ServicesSection />}/> } />
        <Route path='/blog' element={<Layout children={<Blog />}/> } />
        <Route path='/contact' element={<Layout children={<Contact />}/> } />
        <Route path='/international-students' element={<Layout children={<InternationalStudents />}/> } />
        <Route path='/foreign-workers' element={<Layout children={<ForeignWork />}/> } />
        <Route path='/permanent-residence' element={<Layout children={<PermanentResidence />}/> } />
      
      </Routes>
    </div> 
 
  );
}

export default App;
