import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';
import Hero from '../components/Hero/Hero';
import StepToFlowSection from '../components/StepToFlowSection/StepToFlowSection';
import ServicesSection from '../components/ServicesSection/ServicesSection';
import WhyCanadaSection from '../components/whyCanda/WhyCanadaSection';
import Testimonials from '../components/Testimonials/Testimonials';
import useToastNotification from '../components/Toast/useToastNotification';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function Home() {
  const { showSuccessToast, showErrorToast } = useToastNotification();
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);

  const handleChange = (date) => {
    setSelectedTime(date);
  };
  const [text, setText] = useState("Submit");
  const formRef = useRef(null);

  useEffect(() => {
    /*======================================
  Banner slider Js
  ========================================*/
    var banner = new Swiper(".banner-slider", {
      // Optional parameters
      loop: true,
      autoplay: {
        delay: 3000,
      },
      spaceBetween: 0,
      effect: "fade",
      // If we need pagination
      pagination: {
        el: ".banner-custom-pagination",
        clickable: true,
      },
      // Navigation arrows
      navigation: {
        nextEl: ".banner-button-custom-next",
        prevEl: ".banner-button-custom-prev"
      },
      speed: 3000,
    });
    /*======================================
choose slider Js //used
========================================*/



    /*======================================
      Testimonial slider Js
      ========================================*/
    const testimonial = new Swiper('.testimonial-1-active', {
      slidesPerView: 2,
      spaceBetween: 20,
      loop: true,
      roundLengths: true,
      autoplay: {
        delay: 1000,
      },
      pagination: {
        el: ".testimonial-1-swiper-dot",
        clickable: true,
      },
      navigation: {
        nextEl: ".testimonial-1-button-next",
        prevEl: ".testimonial-1-button-prev",
      },
      breakpoints: {
        '1400': {
          slidesPerView: 2,
        },
        '1200': {
          slidesPerView: 2,
        },
        '992': {
          slidesPerView: 1,
        },
        '768': {
          slidesPerView: 1,
        },
        '576': {
          slidesPerView: 1,
        },
        '0': {
          slidesPerView: 1,
        },
      },
      speed: 3000,

    });
    /*======================================
    brand slider Js //used
    ========================================*/
    const brand = new Swiper('.brand-active', {
      slidesPerView: 5,
      spaceBetween: 70,
      loop: true,
      autoplay: {
        delay: 3000,
      },
      breakpoints: {
        '1400': {
          slidesPerView: 5,
        },
        '1200': {
          slidesPerView: 4,
        },
        '992': {
          slidesPerView: 4,
        },
        '768': {
          slidesPerView: 3,
        },
        '576': {
          slidesPerView: 2,
        },
        '0': {
          slidesPerView: 1,
        },
      },
      speed: 3000,
    });


    /*======================================
    coaching slider Js //used
    ========================================*/
    var coachingOne = new Swiper('.coaching-1-active', {
      slidesPerView: 3,
      spaceBetween: 20,
      loop: true,
      autoplay: {
        delay: 1000,
        disableOnInteraction: false,
      },
      pagination: {
        el: ".coaching-1-dot",
        clickable: true,
      },
      navigation: {
        nextEl: ".coaching-1-next",
        prevEl: ".coaching-1-prev",
      },
      breakpoints: {
        '1200': {
          slidesPerView: 3,
        },
        '992': {
          slidesPerView: 2,
        },
        '768': {
          slidesPerView: 2,
        },
        '576': {
          slidesPerView: 1,
        },
        '0': {
          slidesPerView: 1,
        },
      },
      speed: 3000,
    });

    /*======================================
      Team slider Js
      ========================================*/
    var teamOne = new Swiper('.team-1-active', {
      slidesPerView: 4,
      spaceBetween: 0,
      loop: true,
      roundLengths: true,
      autoplay: {
        delay: 2000,
      },
      navigation: {
        nextEl: ".team-1-button-next",
        prevEl: ".team-1-button-prev",
      },
      breakpoints: {
        '1400': {
          slidesPerView: 4,
        },
        '1200': {
          slidesPerView: 4,
        },
        '992': {
          slidesPerView: 3,
        },
        '768': {
          slidesPerView: 2,
        },
        '576': {
          slidesPerView: 1,
        },
        '0': {
          slidesPerView: 1,
        },
      },
      speed: 3000,
    });

  }, []);
  const submit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    let allFieldsFilled = true;

    for (let [key, value] of formData.entries()) {
      if (!value.trim()) {
        allFieldsFilled = false;
        break;
      }
    }

    if (!allFieldsFilled) {
      showErrorToast("Please fill all fields.");
      return;
    }

    setText("Sending..");
    formData.append("access_key", "336c68a0-b877-41d3-acf9-ab97b3416c12");

    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: formData,
      });

      const data = await response.json();
      if (data.success) {
        showSuccessToast(data.message);
        formRef.current.reset();
      } else {
        showErrorToast(`Error: ${data.message}`);
      }
    } catch (error) {
      showErrorToast("An unexpected error occurred.");
    } finally {
      setText("Submit");
    }
  };
  return (
    <>
      <div className="page-wrapper">
        {/* Hero Section */}
        <Hero />
        {/* Hero Section End */}
        {/* Step to follow Section*/}
        <StepToFlowSection />
        <section className="consulting-firm-section section-space-top section-space-medium-bottom p-relative">
          <div className="shape-1">
            <img src="assets/imgs/resources/shape-glob.png" alt="shape" />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="con-firm-image pr-130 p-relative">
                  <figure className="w-img image">
                    <img src="assets/imgs/resources/cf-1.png" alt="consulting-image" />
                  </figure>
                  <div className="wptb-radial-progress text-center">
                    <h6>Success Rate</h6>
                    <div className="wptb-item--inner">
                      <div className="wptb-item--image">
                        <svg className="radial-progress" data-countervalue={95} viewBox="0 0 80 80">
                          <circle className="bar-static" cx={40} cy={40} r={35} />
                          <circle className="bar--animated" cx={40} cy={40} r={35} style={{ strokeDashoffset: '217.8' }} />
                          <text className="countervalue start" x="50%" y="54%" transform="matrix(0, 1, -1, 0, 80, 0)">95</text>
                        </svg>
                      </div>
                    </div>
                    <div className="flags p-relative">
                      <img className="three" src="assets/imgs/resources/flag-3.png" alt="flag" />
                      <span>Canada</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="con-firm-content p-relative">
                  <div className="title-box wow mb-40 fadeInDown p-relative" data-wow-delay="500ms">
                    <h5 className="section-sub-title"><span>|</span> About Us <span>|</span></h5>
                    <h2 className="section-title">Immigration Consulting<br />Firm</h2>
                    <p>Immigra Consultants Private Limited is your trusted partner for navigating the Canadian immigration process. As proud members of the Immigration Consultants of Canada Regulatory Council (ICCRC), we deliver top-tier Regulated Canadian Immigration Consultant Services. With our comprehensive knowledge and expertise, we are dedicated to guiding you on the path towards Canadian immigration success.
                      Renowned for our meticulous attention to detail, we ensure a seamless application process for our clients. Our commitment extends beyond mere immigration assistance; we also prioritize facilitating your integration into Canadian society and your new life in Canada. At Immigra Consultants, we uphold the values of integrity and excellence in every service we provide.
                      Whether your aspirations involve studying abroad, relocating permanently, or exploring Canada as a visitor, we are here to address all your inquiries and concerns. Our experienced staff is equipped to handle a wide range of applications and requests, ensuring personalized support tailored to your needs. </p>
                  </div>
                  <Link to="/" className="primary-btn-1 btn-hover" href="about.html">
                    Get Started
                    <span style={{ top: '147.172px', left: '108.5px' }} />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Step to follow Section End*/}
        <ServicesSection />
        <Testimonials />
        <section className="coaching-section p-relative">
          <div className="bg-shape" />
          <div className="container">
            <div className="title-box text-center wow mb-60 fadeInDown p-relative" data-wow-delay="500ms">
              <h5 className="section-sub-title"><span>|</span> Information <span>|</span></h5>
              <h2 className="section-title">Who We Are</h2>
            </div>
            <div className="swiper coaching-1-active">
              <div className="swiper-wrapper p-relative coaching-swiper">
                <div className="swiper-slide swiper-slide-coaching-box-area">
                  <div className="coaching-box-area">
                    <figure className="w-img image p-relative">
                      <img src="assets/imgs/coaching/approach.png" alt="coaching-image" />
                    </figure>
                    <div className="content text-center">
                      <h4><Link to="/">The Immigrant Canada Firm</Link></h4>
                      <p id='who_we_are'>Our aims regarding the immigration process for CANADA, based on the personalised profile of each candidate.The main office is located on the outskirts of the city of Montreal, Canada, and the secondary office is located in Paris, France.</p>
                      <Link to="coaching.html" className="coaching-btn"><i className="icon-24" /></Link>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide swiper-slide-coaching-box-area">
                  <div className="coaching-box-area">
                    <figure className="w-img image p-relative">
                      <img src="assets/imgs/coaching/approved.png" alt="coaching-image" />
                    </figure>
                    <div className="content text-center">
                      <h4><Link to="/">Verified by Canadian Government</Link></h4>

                      <p id='who_we_are'>As approved consultants,our role is to find the best solutions for clients to immigrate to Canada successfully.Trust is crucial,so we obtained Canadian Government accreditation to operate legally and responsibl and ensuring unwavering support for our candidates.</p>

                      <Link to="/" className="coaching-btn"><i className="icon-24" /></Link>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide swiper-slide-coaching-box-area">
                  <div className="coaching-box-area">
                    <figure className="w-img image p-relative">
                      <img src="assets/imgs/coaching/Immigrants canada.png" alt="coaching-image" />
                    </figure>
                    <div className="content text-center">
                      <h4><Link to="/">Verified & Responsible Approach</Link></h4>
                      <p id='who_we_are'>The IMMIGRAR CANADA firm consists of certified RCIC consultants by the CICC. Our team stays updated on immigration policies through regular training. We offer optimal services and have educational partners to welcome future students.</p>
                      <Link to="/" className="coaching-btn"><i className="icon-24" /></Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Why Canda Section */}
        <WhyCanadaSection />
        {/* Why Canda Section end */}
        <section className="benefits-section section-space p-relative">
          <div className="shape-2">
            <img src="assets/imgs/resources/plane-black.png" alt="Not found" />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="benefits-left-area pr-100 mt-5">
                  <div className="title-box wow mt-5 fadeInDown p-relative mt-5" data-wow-delay="500ms">
                    <h5 className="section-sub-title"><span>|</span> Why Us <span>|</span></h5>
                    <h2 className="section-title ">Our Expertise</h2>
                  </div>
                  <div className="icon-box wow fadeInDown p-relative" data-wow-delay="700ms">
                    <div className="icon-area">
                      <i className="icon-25" />
                    </div>
                    <div className="content">
                      <h5><a href="about.html">Study Permits</a></h5>
                      <p>Undertaking studies in Canada requires first obtaining a study permit, known as a “Study Visa”.</p>
                    </div>
                  </div>
                  <div className="icon-box wow fadeInDown p-relative" data-wow-delay="700ms">
                    <div className="icon-area">
                      <i className="icon-25" />
                    </div>
                    <div className="content">
                      <h5><a href="about.html">Working License</a></h5>
                      <p>When you are neither Canadian nor a permanent resident, you need “Work Visa”</p>
                    </div>
                  </div>
                  <div className="icon-box wow fadeInDown p-relative" data-wow-delay="700ms">
                    <div className="icon-area">
                      <i className="icon-25" />
                    </div>
                    <div className="content">
                      <h5><a href="about.html">Permanent Residence</a></h5>
                      <p>Moving to Canada means, for many, settling there for the long term, without restriction in relation to the activity carried out</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 benefits-right-area p-relative" data-background="assets/imgs/resources/benefits-1.jpg" style={{ backgroundImage: "url('assets/imgs/resources/benefits-1.jpg')" }}>
                <div className="benefits-right-area-1">
                  <div className="float-icon-area">
                    <div className="icon-box">
                      <i className="icon-27" />
                      <div className="content">
                        <h3><span className="counter">20</span>+</h3>
                        <h6>Worldwide Office</h6>
                      </div>
                    </div>
                    <div className="icon-box">
                      <i className="icon-28" />
                      <div className="content">
                        <h3><span className="counter">5.7</span>k</h3>
                        <h6>Visa Success</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Team Section */}
        <section className="team-section section-space-top p-relative">
          <div className="shape-1" data-background="assets/imgs/team/shape-team.png" style={{ backgroundImage: "url('assets/imgs/team/shape-team.png')" }} />
          <div className="container-fluid p-0">
            <div className="title-box text-center wow mb-60 fadeInDown p-relative" data-wow-delay="500ms">
              <h5 className="section-sub-title"><span>|</span> Our Expert <span>|</span></h5>
              <h2 className="section-title">Our Experience Team <br />Member</h2>
            </div>
            <div className="swiper team-1-active">
              <div className="swiper-wrapper p-relative">
                <div className="swiper-slide">
                  <div className="team-box-area p-relative">
                    <figure>
                      <img src="assets/imgs/team/1.png" alt="team-menmber" />
                    </figure>
                    <div className="content">
                      <ul>
                        <li><Link to="#"><i className="fab fa-facebook-f" /></Link></li>
                        <li><Link to="#"><i className="fab fa-twitter" /></Link></li>
                        <li><Link to="#"><i className="fab fa-linkedin-in" /></Link></li>
                        <li><Link to="#"><i className="fab fa-dribbble" /></Link></li>
                      </ul>
                      <h3><Link to="team-details.html">Banon RUJID</Link></h3>
                      <span>Associate | Director</span>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="team-box-area p-relative">
                    <figure>
                      <img src="assets/imgs/team/2.png" alt="team-menmber" />
                    </figure>
                    <div className="content">
                      <ul>
                        <li><Link to="#"><i className="fab fa-facebook-f" /></Link></li>
                        <li><Link to="#"><i className="fab fa-twitter" /></Link></li>
                        <li><Link to="#"><i className="fab fa-linkedin-in" /></Link></li>
                        <li><Link to="#"><i className="fab fa-dribbble" /></Link></li>
                      </ul>
                      <h3><Link to="team-details.html">Alex GUO</Link></h3>
                      <span>Associate | Director & Approved Consultant</span>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="team-box-area p-relative">
                    <figure>
                      <img src="assets/imgs/team/3.png" alt="team-menmber" />
                    </figure>
                    <div className="content">
                      <ul>
                        <li><Link to="#"><i className="fab fa-facebook-f" /></Link></li>
                        <li><Link to="#"><i className="fab fa-twitter" /></Link></li>
                        <li><Link to="#"><i className="fab fa-linkedin-in" /></Link></li>
                        <li><Link to="#"><i className="fab fa-dribbble" /></Link></li>
                      </ul>
                      <h3><Link to="team-details.html">Vanesa GARCIA</Link></h3>
                      <span>Partner</span>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="team-box-area p-relative">
                    <figure>
                      <img src="assets/imgs/team/4.png" alt="team-menmber" />
                    </figure>
                    <div className="content">
                      <ul>
                        <li><Link to="#"><i className="fab fa-facebook-f" /></Link></li>
                        <li><Link to="#"><i className="fab fa-twitter" /></Link></li>
                        <li><Link to="#"><i className="fab fa-linkedin-in" /></Link></li>
                        <li><Link to="#"><i className="fab fa-dribbble" /></Link></li>
                      </ul>
                      <h3><Link to="team-details.html">Joel SANGARE</Link></h3>
                      <span>Partner Lawyer</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Team Section  End*/}

        <div className="title-box text-center wow mb-60 fadeInDown p-relative mt-5" data-wow-delay="500ms">
          <h5 className="section-sub-title"><span>|</span> Contact US <span>|</span></h5>
          <h2 className="section-title">General Queries And<br />Information</h2>
        </div>
        <section className="faq-section section-space-top p-relative bg-color-9">
          <div className="shape-1">
            <img src="assets/imgs/resources/booking-plane.png" alt="shape" />
          </div>
          <div className="shape-2">
            <img src="assets/imgs/resources/booking-plane-2.png" alt="shape" />
          </div>
          <div className="container">

            <div className="row">
              <div className="col-lg-5">
                <div className="faq-box-area">
                  <div className="title-box wow mb-10 fadeInDown p-relative" data-wow-delay="500ms">
                    <h5 className="section-sub-title"><span>|</span> Frequently Asked Question <span>|</span></h5>
                    <h2 className="section-title">Questions &amp; Answer</h2>
                  </div>
                  <div className="vw-faq">
                    <div className="accordion" id="accordionExample-st-2">
                      <div className="vw-faq-group">
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingOne-st-2">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne-st-2" aria-expanded="false" aria-controls="collapseOne-st-2">
                              What are the different immigration programs available for Pakistanis looking to immigrate to Canada?
                            </button>
                          </h2>
                          <div id="collapseOne-st-2" className="accordion-collapse collapse" aria-labelledby="headingOne-st-2" data-bs-parent="#accordionExample-st-2">
                            <div className="accordion-body">
                              Answer: Canada offers various immigration programs suitable for individuals from Pakistan. These include the Express Entry system, Provincial Nominee Programs (PNPs), Family Sponsorship, and specific streams for entrepreneurs, investors, and skilled workers.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingTwo-st-2">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo-st-2" aria-expanded="false" aria-controls="collapseTwo-st-2">
                              How does the Express Entry system work for Pakistani applicants?
                            </button>
                          </h2>
                          <div id="collapseTwo-st-2" className="accordion-collapse collapse" aria-labelledby="headingTwo-st-2" data-bs-parent="#accordionExample-st-2">
                            <div className="accordion-body">
                              The Express Entry system is an online system used to manage applications for permanent residence under certain economic immigration programs. Pakistani candidates create an online profile, and those who meet the criteria are entered into a pool, from which the highest-ranking candidates are invited to apply for permanent residence.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingThree-st-2">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree-st-2" aria-expanded="false" aria-controls="collapseThree-st-2">
                              What steps can Pakistani applicants take to improve their chances of being selected for Express Entry?
                            </button>
                          </h2>
                          <div id="collapseThree-st-2" className="accordion-collapse collapse" aria-labelledby="headingThree-st-2" data-bs-parent="#accordionExample-st-2">
                            <div className="accordion-body">
                              Pakistani applicants can improve their Comprehensive Ranking System (CRS) score by enhancing their language skills, obtaining additional education or Canadian work experience, securing a job offer or a provincial nomination, and ensuring their profile is up-to-date and accurate.
                            </div>
                          </div>
                        </div>

                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingThree-st-3">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree-st-3" aria-expanded="false" aria-controls="collapseThree-st-3">
                              Can Pakistanis participate in Provincial Nominee Programs (PNPs) for Canadian immigration?
                            </button>
                          </h2>
                          <div id="collapseThree-st-3" className="accordion-collapse collapse" aria-labelledby="headingThree-st-3" data-bs-parent="#accordionExample-st-2">
                            <div className="accordion-body">
                              Yes, individuals from Pakistan can participate in Provincial Nominee Programs (PNPs), which allow Canadian provinces and territories to nominate individuals who wish to immigrate and settle in a particular province or territory. Each province and territory has its own PNP with specific eligibility criteria and streams tailored to its economic and demographic needs.
                            </div>
                          </div>
                        </div>

                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingFour-st-3">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour-st-3" aria-expanded="false" aria-controls="collapseFour-st-3">
                              What are the eligibility requirements for Pakistani applicants seeking Canadian citizenship?
                            </button>
                          </h2>
                          <div id="collapseFour-st-3" className="accordion-collapse collapse" aria-labelledby="headingFour-st-3" data-bs-parent="#accordionExample-st-3">
                            <div className="accordion-body">
                              Pakistani applicants seeking Canadian citizenship must meet residency requirements, language proficiency, and knowledge of Canada criteria. Generally, applicants must have been physically present in Canada for at least 1,095 days (three years) within the five years preceding the date of their application..
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="booking-area ">
                  <div className="booking-form--wrapper text-center" data-background="assets/imgs/resources/booking-map.png">
                    <form ref={formRef} onSubmit={submit}>
                      <div className="booking-form--inner">
                        <h2>Book An Appointment </h2>
                        <div className="row">
                          <div className="col-md-6 col-lg-6 mb-4">
                            <div className="common form-group">
                              <select name="country" className="form-control">
                                <option value="">Select Country</option>
                                <option value="Japan">Japan</option>
                                <option value="Korea">Korea</option>
                                <option value="Australia">Australia</option>
                                <option value="Canada">Canada</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-6 mb-4">
                            <div className="common form-group">
                              <select name="services" className="form-control">
                                <option value="">Select Service</option>
                                <option value="GRE Exam">GRE Exam</option>
                                <option value="IELTS Exam">IELTS Exam</option>
                                <option value="Student Visa">Student Visa</option>
                                <option value="Family Visa">Family Visa</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-6 mb-4">
                            <div className="form-group">
                              <input id="name" type="text" name="name" className="form-control" placeholder="Full Name" />
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-6 mb-4">
                            <div className="form-group">
                              <input id="phone" type="text" name="phone" className="form-control" placeholder="Phone Number" />
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-6 mb-4">
                            <div className="p-relative mt-4 Datepicker-wrapper" style={{ borderBottom: '1px solid rgb(216, 27, 61, 100%)', color: "rgb(216, 27, 61, 100%)", paddingBottom: "5px", cursor: "pointer", width: "100%"}}>
                            <DatePicker placeholderText={'Select a Date'} name='date' selected={selectedDate} onChange={(date) => setSelectedDate(date)} showYearDropdown />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-6 mb-4">
                          <div className="p-relative mt-4 Datepicker-wrapper" style={{ borderBottom: '1px solid rgb(216, 27, 61, 100%)', color: "rgb(216, 27, 61, 100%)", paddingBottom: "5px", cursor: "pointer" }}>
                            <DatePicker
                              selected={selectedTime}
                              onChange={handleChange}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={30}
                              name='time'
                              timeCaption="Time"
                              dateFormat="h:mm aa"
                              placeholderText="Select a time"
                            />
                          </div>
                        </div>

                        <div className="col-md-12 col-lg-12 mt-20">
                          <div className="booking--button text-center">
                            <button type="submit" className="btn2">{text}</button>
                          </div>
                        </div>
                      </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
      </div>
    </div >
        </section >


    <section className="blog-section section-space p-relative text-center">
      <div className="container">
        <div className="title-box wow mb-35 fadeInDown p-relative" data-wow-delay="500ms">
          <h5 className="section-sub-title"><span>|</span> News & Updates<span>|</span></h5>
          <h2 className="section-title">Latest News &amp; Articles From<br /> The Blog</h2>
        </div>
        <div className="row g-4">
          <div className="col-lg-4 mb-20">
            <div className="blog-style-1 p-relative">
              <Link to="blog-details.html" className="w-img image">
                <img src="assets/imgs/blog/blog-1.jpg" alt="blog-image" />
                <img src="assets/imgs/blog/blog-1.jpg" alt="blog-image" />
              </Link>
              <div className="content p-relative">
                <div className="date-excerpt">
                  <Link to="#">16 Apr</Link>
                </div>
                <div className="info-excerpt">
                  <div className="tags">
                    <svg width={15} height={16} viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_2032_2240)">
                        <path d="M13.621 0.497487C13.6125 0.497487 13.6041 0.497487 13.5957 0.497487C13.5882 0.497487 13.5816 0.496549 13.5741 0.497487H8.61754C8.4741 0.504987 8.14785 0.72905 8.10566 0.771237L2.13848 6.73749C1.77285 7.10311 1.77285 7.69561 2.13848 8.06124L2.88285 8.80561L2.13848 9.54999C1.77285 9.91561 1.77285 10.5081 2.13848 10.8737L6.4941 15.2294C6.67691 15.4122 6.91598 15.5031 7.15598 15.5031C7.39598 15.5031 7.63504 15.4122 7.81785 15.2294L13.7841 9.26218C13.8272 9.21999 14.0625 8.93124 14.0625 8.75124V5.93874V3.77593V0.963425C14.0757 0.701862 13.8779 0.497487 13.621 0.497487ZM12.6563 1.43124C12.915 1.43124 13.125 1.64124 13.125 1.89999C13.125 2.15874 12.915 2.36874 12.6563 2.36874C12.3975 2.36874 12.1875 2.15874 12.1875 1.89999C12.1875 1.64124 12.3975 1.43124 12.6563 1.43124ZM13.1222 8.6003L7.15785 14.5675L2.80223 10.2137L3.5466 9.46936L6.49504 12.4178C6.67785 12.6006 6.91691 12.6916 7.15691 12.6916C7.39691 12.6916 7.63598 12.6006 7.81879 12.4178L13.125 7.11061V8.5928C13.1222 8.59749 13.1204 8.6003 13.1222 8.6003C13.1213 8.6003 13.1213 8.6003 13.1222 8.6003Z" fill="#E13833" />
                      </g>
                      <defs>
                        <clipPath>
                          <rect width={15} height="15.0066" fill="white" transform="translate(0 0.49707)" />
                        </clipPath>
                      </defs>
                    </svg>
                    <Link to="#">Design</Link>
                  </div>
                  <div className="comments">
                    <svg width={15} height={16} viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.88844 2.29862C7.95694 1.72411 6.85313 1.4082 5.71289 1.4082C2.609 1.4082 0 3.72069 0 6.68164C0 7.71978 0.3225 8.71496 0.935098 9.57444L0.0793359 12.2611C-0.0110742 12.5449 0.201504 12.834 0.498135 12.834C0.565957 12.834 0.634219 12.8183 0.697207 12.7863L3.29971 11.463C3.40509 11.5084 3.51179 11.5506 3.61957 11.5898C3.01702 10.6493 2.69531 9.56973 2.69531 8.43945C2.69531 5.07333 5.52768 2.48551 8.88844 2.29862Z" fill="#5D666F" />
                      <path d="M14.0649 11.3323C14.6775 10.4728 15 9.47759 15 8.43945C15 5.47742 12.3899 3.16602 9.28711 3.16602C6.18322 3.16602 3.57422 5.47851 3.57422 8.43945C3.57422 11.4015 6.18434 13.7129 9.28711 13.7129C10.1196 13.7129 10.9492 13.5432 11.7001 13.2208L14.3028 14.5441C14.4607 14.6244 14.6515 14.602 14.7865 14.4872C14.9216 14.3725 14.9745 14.1878 14.9207 14.019L14.0649 11.3323ZM7.5 8.87891C7.2573 8.87891 7.06055 8.68215 7.06055 8.43945C7.06055 8.19676 7.2573 8 7.5 8C7.7427 8 7.93945 8.19676 7.93945 8.43945C7.93945 8.68215 7.7427 8.87891 7.5 8.87891ZM9.25781 8.87891C9.01512 8.87891 8.81836 8.68215 8.81836 8.43945C8.81836 8.19676 9.01512 8 9.25781 8C9.50051 8 9.69727 8.19676 9.69727 8.43945C9.69727 8.68215 9.50051 8.87891 9.25781 8.87891ZM11.0156 8.87891C10.7729 8.87891 10.5762 8.68215 10.5762 8.43945C10.5762 8.19676 10.7729 8 11.0156 8C11.2583 8 11.4551 8.19676 11.4551 8.43945C11.4551 8.68215 11.2583 8.87891 11.0156 8.87891Z" fill="#E13833" />
                    </svg>
                    <Link to="#">2 Comments</Link>
                  </div>
                </div>
                <h4 className="mt-10 mb-20"> <Link to="blog-details.html">Unsatiable Entreaties May Collecting Power.</Link></h4>
                <Link to="blog-details.html" className="blog-btn"><i className="icon-4" /></Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="blog-style-1 p-relative">
              <Link to="blog-details.html" className="w-img image">
                <img src="assets/imgs/blog/blog-2.jpg" alt="blog-image" />
                <img src="assets/imgs/blog/blog-2.jpg" alt="blog-image" />
              </Link>
              <div className="content p-relative">
                <div className="date-excerpt">
                  <Link to="#">16 Apr</Link>
                </div>
                <div className="info-excerpt">
                  <div className="tags">
                    <svg width={15} height={16} viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_2032_2240)">
                        <path d="M13.621 0.497487C13.6125 0.497487 13.6041 0.497487 13.5957 0.497487C13.5882 0.497487 13.5816 0.496549 13.5741 0.497487H8.61754C8.4741 0.504987 8.14785 0.72905 8.10566 0.771237L2.13848 6.73749C1.77285 7.10311 1.77285 7.69561 2.13848 8.06124L2.88285 8.80561L2.13848 9.54999C1.77285 9.91561 1.77285 10.5081 2.13848 10.8737L6.4941 15.2294C6.67691 15.4122 6.91598 15.5031 7.15598 15.5031C7.39598 15.5031 7.63504 15.4122 7.81785 15.2294L13.7841 9.26218C13.8272 9.21999 14.0625 8.93124 14.0625 8.75124V5.93874V3.77593V0.963425C14.0757 0.701862 13.8779 0.497487 13.621 0.497487ZM12.6563 1.43124C12.915 1.43124 13.125 1.64124 13.125 1.89999C13.125 2.15874 12.915 2.36874 12.6563 2.36874C12.3975 2.36874 12.1875 2.15874 12.1875 1.89999C12.1875 1.64124 12.3975 1.43124 12.6563 1.43124ZM13.1222 8.6003L7.15785 14.5675L2.80223 10.2137L3.5466 9.46936L6.49504 12.4178C6.67785 12.6006 6.91691 12.6916 7.15691 12.6916C7.39691 12.6916 7.63598 12.6006 7.81879 12.4178L13.125 7.11061V8.5928C13.1222 8.59749 13.1204 8.6003 13.1222 8.6003C13.1213 8.6003 13.1213 8.6003 13.1222 8.6003Z" fill="#E13833" />
                      </g>
                      <defs>
                        <clipPath>
                          <rect width={15} height="15.0066" fill="white" transform="translate(0 0.49707)" />
                        </clipPath>
                      </defs>
                    </svg>
                    <Link to="#">Business</Link>
                  </div>
                  <div className="comments">
                    <svg width={15} height={16} viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.88844 2.29862C7.95694 1.72411 6.85313 1.4082 5.71289 1.4082C2.609 1.4082 0 3.72069 0 6.68164C0 7.71978 0.3225 8.71496 0.935098 9.57444L0.0793359 12.2611C-0.0110742 12.5449 0.201504 12.834 0.498135 12.834C0.565957 12.834 0.634219 12.8183 0.697207 12.7863L3.29971 11.463C3.40509 11.5084 3.51179 11.5506 3.61957 11.5898C3.01702 10.6493 2.69531 9.56973 2.69531 8.43945C2.69531 5.07333 5.52768 2.48551 8.88844 2.29862Z" fill="#5D666F" />
                      <path d="M14.0649 11.3323C14.6775 10.4728 15 9.47759 15 8.43945C15 5.47742 12.3899 3.16602 9.28711 3.16602C6.18322 3.16602 3.57422 5.47851 3.57422 8.43945C3.57422 11.4015 6.18434 13.7129 9.28711 13.7129C10.1196 13.7129 10.9492 13.5432 11.7001 13.2208L14.3028 14.5441C14.4607 14.6244 14.6515 14.602 14.7865 14.4872C14.9216 14.3725 14.9745 14.1878 14.9207 14.019L14.0649 11.3323ZM7.5 8.87891C7.2573 8.87891 7.06055 8.68215 7.06055 8.43945C7.06055 8.19676 7.2573 8 7.5 8C7.7427 8 7.93945 8.19676 7.93945 8.43945C7.93945 8.68215 7.7427 8.87891 7.5 8.87891ZM9.25781 8.87891C9.01512 8.87891 8.81836 8.68215 8.81836 8.43945C8.81836 8.19676 9.01512 8 9.25781 8C9.50051 8 9.69727 8.19676 9.69727 8.43945C9.69727 8.68215 9.50051 8.87891 9.25781 8.87891ZM11.0156 8.87891C10.7729 8.87891 10.5762 8.68215 10.5762 8.43945C10.5762 8.19676 10.7729 8 11.0156 8C11.2583 8 11.4551 8.19676 11.4551 8.43945C11.4551 8.68215 11.2583 8.87891 11.0156 8.87891Z" fill="#E13833" />
                    </svg>
                    <Link to="#">3 Comments</Link>
                  </div>
                </div>
                <h4 className="mt-10 mb-20"> <Link to="blog-details.html">It’s through refresing to get such a personal </Link></h4>
                <Link to="blog-details.html" className="blog-btn"><i className="icon-4" /></Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="blog-style-1 p-relative">
              <Link to="blog-details.html" className="w-img image">
                <img src="assets/imgs/blog/blog-3.jpg" alt="blog-image" />
                <img src="assets/imgs/blog/blog-3.jpg" alt="blog-image" />
              </Link>
              <div className="content p-relative">
                <div className="date-excerpt">
                  <Link to="#">18 Apr</Link>
                </div>
                <div className="info-excerpt">
                  <div className="tags">
                    <svg width={15} height={16} viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_2032_2240)">
                        <path d="M13.621 0.497487C13.6125 0.497487 13.6041 0.497487 13.5957 0.497487C13.5882 0.497487 13.5816 0.496549 13.5741 0.497487H8.61754C8.4741 0.504987 8.14785 0.72905 8.10566 0.771237L2.13848 6.73749C1.77285 7.10311 1.77285 7.69561 2.13848 8.06124L2.88285 8.80561L2.13848 9.54999C1.77285 9.91561 1.77285 10.5081 2.13848 10.8737L6.4941 15.2294C6.67691 15.4122 6.91598 15.5031 7.15598 15.5031C7.39598 15.5031 7.63504 15.4122 7.81785 15.2294L13.7841 9.26218C13.8272 9.21999 14.0625 8.93124 14.0625 8.75124V5.93874V3.77593V0.963425C14.0757 0.701862 13.8779 0.497487 13.621 0.497487ZM12.6563 1.43124C12.915 1.43124 13.125 1.64124 13.125 1.89999C13.125 2.15874 12.915 2.36874 12.6563 2.36874C12.3975 2.36874 12.1875 2.15874 12.1875 1.89999C12.1875 1.64124 12.3975 1.43124 12.6563 1.43124ZM13.1222 8.6003L7.15785 14.5675L2.80223 10.2137L3.5466 9.46936L6.49504 12.4178C6.67785 12.6006 6.91691 12.6916 7.15691 12.6916C7.39691 12.6916 7.63598 12.6006 7.81879 12.4178L13.125 7.11061V8.5928C13.1222 8.59749 13.1204 8.6003 13.1222 8.6003C13.1213 8.6003 13.1213 8.6003 13.1222 8.6003Z" fill="#E13833" />
                      </g>
                      <defs>
                        <clipPath>
                          <rect width={15} height="15.0066" fill="white" transform="translate(0 0.49707)" />
                        </clipPath>
                      </defs>
                    </svg>
                    <Link to="#">Design</Link>
                  </div>
                  <div className="comments">
                    <svg width={15} height={16} viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.88844 2.29862C7.95694 1.72411 6.85313 1.4082 5.71289 1.4082C2.609 1.4082 0 3.72069 0 6.68164C0 7.71978 0.3225 8.71496 0.935098 9.57444L0.0793359 12.2611C-0.0110742 12.5449 0.201504 12.834 0.498135 12.834C0.565957 12.834 0.634219 12.8183 0.697207 12.7863L3.29971 11.463C3.40509 11.5084 3.51179 11.5506 3.61957 11.5898C3.01702 10.6493 2.69531 9.56973 2.69531 8.43945C2.69531 5.07333 5.52768 2.48551 8.88844 2.29862Z" fill="#5D666F" />
                      <path d="M14.0649 11.3323C14.6775 10.4728 15 9.47759 15 8.43945C15 5.47742 12.3899 3.16602 9.28711 3.16602C6.18322 3.16602 3.57422 5.47851 3.57422 8.43945C3.57422 11.4015 6.18434 13.7129 9.28711 13.7129C10.1196 13.7129 10.9492 13.5432 11.7001 13.2208L14.3028 14.5441C14.4607 14.6244 14.6515 14.602 14.7865 14.4872C14.9216 14.3725 14.9745 14.1878 14.9207 14.019L14.0649 11.3323ZM7.5 8.87891C7.2573 8.87891 7.06055 8.68215 7.06055 8.43945C7.06055 8.19676 7.2573 8 7.5 8C7.7427 8 7.93945 8.19676 7.93945 8.43945C7.93945 8.68215 7.7427 8.87891 7.5 8.87891ZM9.25781 8.87891C9.01512 8.87891 8.81836 8.68215 8.81836 8.43945C8.81836 8.19676 9.01512 8 9.25781 8C9.50051 8 9.69727 8.19676 9.69727 8.43945C9.69727 8.68215 9.50051 8.87891 9.25781 8.87891ZM11.0156 8.87891C10.7729 8.87891 10.5762 8.68215 10.5762 8.43945C10.5762 8.19676 10.7729 8 11.0156 8C11.2583 8 11.4551 8.19676 11.4551 8.43945C11.4551 8.68215 11.2583 8.87891 11.0156 8.87891Z" fill="#E13833" />
                    </svg>
                    <Link to="#">No Comments</Link>
                  </div>
                </div>
                <h4 className="mt-10 mb-20"> <Link to="blog-details.html">Due to their excellent service competitive pricing </Link></h4>
                <Link to="blog-details.html" className="blog-btn"><i className="icon-4" /></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
      </div >
    </>
  );
}

export default Home;
