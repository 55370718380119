import React from 'react'
import { Link } from 'react-router-dom'
const AboutUs = () => {
  return (
   
    <section className="consulting-firm-section section-space-top section-space-medium-bottom p-relative">
    <div className="shape-1">
      <img src="assets/imgs/resources/shape-glob.png" alt="shape" />
    </div>
    <div className="container">
      <div className="row">
        <div className="col-lg-6">
          <div className="con-firm-image pr-130 p-relative">
            <figure className="w-img image">
              <img src="assets/imgs/resources/cf-1.png" alt="consulting-image" />
            </figure>
            <div className="wptb-radial-progress text-center">
              <h6>Success Rate</h6>
              <div className="wptb-item--inner">
                <div className="wptb-item--image">
                  <svg className="radial-progress" data-countervalue={95} viewBox="0 0 80 80">
                    <circle className="bar-static" cx={40} cy={40} r={35} />
                    <circle className="bar--animated" cx={40} cy={40} r={35} style={{ strokeDashoffset: '217.8' }} />
                    <text className="countervalue start" x="50%" y="54%" transform="matrix(0, 1, -1, 0, 80, 0)">95</text>
                  </svg>
                </div>
              </div>
              <div className="flags p-relative">
                <img className="three" src="assets/imgs/resources/flag-3.png" alt="flag" />
                <span>Canada</span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="con-firm-content p-relative">
            <div className="title-box wow mb-40 fadeInDown p-relative" data-wow-delay="500ms">
              <h5 className="section-sub-title"><span>|</span> About Us <span>|</span></h5>
              <h2 className="section-title">Immigration Consulting<br />Firm</h2>
              <p>Immigra Consultants Private Limited is your trusted partner for navigating the Canadian immigration process. As proud members of the Immigration Consultants of Canada Regulatory Council (ICCRC), we deliver top-tier Regulated Canadian Immigration Consultant Services. With our comprehensive knowledge and expertise, we are dedicated to guiding you on the path towards Canadian immigration success.
                Renowned for our meticulous attention to detail, we ensure a seamless application process for our clients. Our commitment extends beyond mere immigration assistance; we also prioritize facilitating your integration into Canadian society and your new life in Canada. At Immigra Consultants, we uphold the values of integrity and excellence in every service we provide.
                Whether your aspirations involve studying abroad, relocating permanently, or exploring Canada as a visitor, we are here to address all your inquiries and concerns. Our experienced staff is equipped to handle a wide range of applications and requests, ensuring personalized support tailored to your needs. </p>
            </div>
            <Link to="/" className="primary-btn-1 btn-hover" href="about.html">
              Get Started
              <span style={{ top: '147.172px', left: '108.5px' }} />
            </Link>
          </div>
        </div>
      </div>
    </div>
  </section>

  )
}

export default AboutUs