import { Link, Route, HashRouter, Routes } from 'react-router-dom';
import Contact from './Contact';
import Home from './Home';
import AboutUs from './AboutUs'
import Blog from './Blog'
import ServicesSection from '../components/ServicesSection/ServicesSection';
import InternationalStudents from '../components/ServicesSection/InternationalStudents.jsx/InternationalStudents';
import ForeignWork from '../components/ServicesSection/ForeignWork/ForeignWork';
import PermanentResidence from '../components/PermanentResidence/PermanentResidence';
import Testimonials from '../components/Testimonials/Testimonials';
import FourOFour from '../components/404 pages/FourOFour';

const MainContent = () => {
  return (
    <>
    
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<AboutUs />} />
        <Route path='/blog' element={<Blog />} />
        <Route path='/testimonials' element={<Testimonials />} />
        <Route path='/our-services' element={<ServicesSection />} />
        <Route path='/international-students' element={<InternationalStudents />} />
        <Route path='/foreign-workers' element={<ForeignWork />} />
        <Route path='/permanent-residence' element={<PermanentResidence />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='*' element={<FourOFour />} />
      </Routes>
      <div className=' fixed-bottom right-100 p-4' style={{ left: "initial" }}>
        <Link to={"https://wa.me/923349594377"} target='#blank'>
          <img src="assets/imgs/whatapp.png" alt="" width='60' />
        </Link>
      </div>
    </>
  )
}

export default MainContent