import React from 'react';
import './StepToFlowSection.css';

const StepToFlowSection = () => {

  const cards = [
    {
      image: "assets/imgs/step-to-follow/1.png",
      title: "provide basic information for immigration",
      description: "We will Provide Basic Information so you can get what your are looking for",
      num: "01"
    },
    {
      image: "assets/imgs/step-to-follow/2.png",
      title: "have a call with us for general queries ",
      description: "Our experts will help you provide more details on call to provide consultancy",
      num: "02"
    },
    {
      image: "assets/imgs/step-to-follow/3.png",
      title: "talk with canadian consultant ",
      description: "In this step we will fix a meeting with Canadian Consultant for further info.",
      num: "03"
    },
    {
      image: "assets/imgs/step-to-follow/4.png",
      title: "get yourself process on the way",
      description: "After all these steps you can easily process yourself on the way.",
      num: "04"
    }
  ];


  return (
    <section id="StepToFlowSection6">


      <div className="myContainer mx-auto py-5 px-0">

        <h6>steps</h6>

        <h1 className="text-center text-capitalize" style={{ fontWeight: "bold" }}>step to follow</h1>

        <div className="d-flex align-items-center justify-content-center flex-wrap gap-3 pt-5 mt-2">

          {cards.map(v => <div key={v.title} className="d-flex  align-items-center  justify-content-between   flex-column  StepToFlowSection-card">

            <figure><img src={v.image} /></figure>

            <div className='d-flex align-items-center justify-content-center flex-column '>
              <h1 className='text-center num'>{v.num}</h1>
              <h1 className='text-capitalize text-center num'>{v.title}</h1>
              <p className='text-center mt-2'>{v.description}</p>
              <i></i>
            </div>

          </div>)}

        </div>

      </div>


    </section>
  )
}

export default StepToFlowSection