import React from 'react'
import './whycanadasection.css'
const WhyCanadaSection = () => {

    const cards = [
        {
            description: "A superior quality of life, a low cost of living, the best employment rate in the world, and a thriving economy have been Canada's main strengths for several years, attracting countless immigrants and global investors.",
            num: "01"
        },
        {
            description: "Winner of all cumulative statistics for living standards, for 10 years, Canada has been propelled to the rank of number 1 destination throughout the world for all people wishing to improve their living conditions as well as their prospects!",
            num: "02"
        },
        {

            description: "Canada also offers one of the best education systems in the world. This system attracts many foreign candidates, whether they are recent graduates in their country of origin, or tempted to return to studies at some point in their career.",
            num: "03"
        },
    ];

    return (
        <section id="StepToFlowSection2">


            <div className="myContainer mx-auto py-5 px-0">

                <h6>why Canada</h6>

                <h1 className="text-center text-capitalize" style={{ fontWeight: "bold" }}>Reasons Why Canada</h1>

                <div className="d-flex align-items-center justify-content-center flex-wrap gap-3">

                    {cards.map(v => <div key={v.num} className="d-flex  align-items-center  justify-content-between   flex-column  StepToFlowSection-card1">

                        <div className='d-flex align-items-center justify-content-center flex-column '>
                            <h1 className='text-center num'>{v.num}</h1>
                            <p className='text-center mt-3 mb-3'>{v.description}</p>
                            <i></i>
                        </div>

                    </div>)}

                </div>

            </div>


        </section>
    )
}

export default WhyCanadaSection
