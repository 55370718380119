import React, { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useToastNotification from '../components/Toast/useToastNotification';
import { Link } from 'react-router-dom';

const Contact = () => {
  const { showSuccessToast, showErrorToast } = useToastNotification();
  const [text, setText] = useState('Send Message');
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    let allFieldsFilled = true;
    for (let [key, value] of formData.entries()) {
      if (!value.trim()) {
        allFieldsFilled = false;
        break;
      }
    }
    if (!allFieldsFilled) {
      showErrorToast("Please fill all fields.");
      return;
    }
    setText("Sending..");
    formData.append("access_key", "336c68a0-b877-41d3-acf9-ab97b3416c12");

    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: formData
      });

      const data = await response.json();
      if (data.success) {
        showSuccessToast(data.message);
        event.target.reset();
      } else {
        showErrorToast(`Error: ${data.message}`);
      }
    } catch (error) {
      showErrorToast("An unexpected error occurred.");
    } finally {
      setText('Send Message');
    }
  };


  return (
    <div className="page-wrapper">
      <div className="breadcrumb__area bg-color-2 p-relative" data-background="assets/imgs/page-title/page-title-8.jpg">
        <div className="shape-bg" data-background="assets/imgs/page-title/pt-bg.png" />
        <div className="shape-1" data-background="assets/imgs/page-title/pt-shape.png" />
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumb__wrapper p-relative">
                <div className="breadcrumb__menu">
                  <nav>
                    <ul>
                      <li><span><a href="index.html">Home</a></span></li>
                      <li><span>Contact</span></li>
                    </ul>
                  </nav>
                </div>
                <h2 className="breadcrumb__title">Contact Us</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="contact-page-section section-space">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="title-box mb-30 wow fadeInRight" data-wow-delay="1500ms">
                <h2 className="section-title mb-20">Feel free to write</h2>
              </div>
              <div className="contact-form">
                <form onSubmit={handleSubmit}>
                  <div className="row g-0">
                    <div className="col-lg-6">
                      <div className="contact-input">
                        <input id="name" name="name" type="text" placeholder="Enter Name" style={{ padding: "5px 0px 5px 15px", border: "1px solid rgb(211, 211, 211, 100%)", color: "#000000", fontFamily: "Cormorant", fontWeight: "600" }} />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="contact-input">
                        <input name="email" type="email" placeholder="Enter Email" style={{ padding: "5px 0px 5px 15px", border: "1px solid rgb(211, 211, 211, 100%)", color: "#000000", fontFamily: "Cormorant", fontWeight: "600" }} />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="contact-input">
                        <input type="text" placeholder="Enter Subject" name="subject" style={{ padding: "5px 0px 5px 15px", border: "1px solid rgb(211, 211, 211, 100%)", color: "#000000", fontFamily: "Cormorant", fontWeight: "600" }} />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="contact-input">
                        <input id="phone" name="number" type="text" placeholder="Enter Phone" style={{ padding: "5px 0px 5px 15px", border: "1px solid rgb(211, 211, 211, 100%)", color: "#000000", fontFamily: "Cormorant", fontWeight: "600" }} />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="contact-input">
                        <textarea name="message" placeholder="Enter Message" defaultValue={""} style={{ padding: "5px 0px 5px 15px", border: "1px solid rgb(211, 211, 211, 100%)", color: "#000000", fontFamily: "Cormorant", fontWeight: "600" }} />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="btn-area pl-10">
                        <button type="submit" className="primary-btn-1 btn-hover">
                          {text}
                          <span style={{ top: '147.172px', left: '108.5px' }} />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

            </div>
            <div className="col-lg-6">
              <div className="contact-page-info-area pl-80">
                <div className="title-box mb-30 wow fadeInRight" data-wow-delay="1500ms">
                  <h2 className="section-title mb-20">Feel free to Contact</h2>
                  <p>Immigra Consultants Private Limited is your trusted partner for navigating the Canadian immigration process.</p>
                </div>
                <div className="contact-icon-box">
                  <div className="icon-box">
                    <i className="icon-36" />
                  </div>
                  <div className="content">
                    <h4>Have any question?</h4>
                    <span>Free : </span>
                    <a href="tel:(406)555-0120">(051) 9450053</a>
                  </div>
                </div>
                <div className="contact-icon-box">
                  <div className="icon-box">
                    <i className="icon-1" />
                  </div>
                  <div className="content">
                    <h4>Write email</h4>
                    <span>Email: </span>
                    <Link href="mailto:info@immigraconsultants.com">info@immigraconsultants.com</Link>
                  </div>
                </div>
                <div className="contact-icon-box">
                  <div className="icon-box">
                    <i className="icon-2" />
                  </div>
                  <div className="content">
                    <h4>Visit anytime</h4>
                    <span>Address: </span>
                    <Link href="https://maps.app.goo.gl/yQLW4ejK23EDobHs5" target="_blank">Office No 06/Ground Floor,Hamdan Heights,ExpressWay ,ISB</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="contact-map mt-5">
          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d4216.433331900906!2d90.36996032419312!3d23.83718617432321!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1693682874850!5m2!1sen!2sbd" />
        </div> */}
        <div className="title-box text-center wow fadeInDown p-relative mt-5" data-wow-delay="500ms">
          <h5 className="section-sub-title"><span>|</span>Associates<span>|</span></h5>
          <h2 className="section-title">OUR INTERNATIONAL<br />PARTNERS</h2>
        </div>
        <div className='row'>
          <div className="div col-lg-6">
            <div className="left11">
              <h2 className='pakistan mb-2'>PAKISTAN</h2>
              <p className='immigria mb-1'>IMMIGRA CONSULTANTS (PRIVATE) LIMITED</p>
              <p className='zafar mb-1'>Represented by Mr. Zafar JAMIL</p>
              <div className="img1 mt-2">
                <img src="assets/imgs/zafar.png" alt="" style={{ width: '252px', height: 'auto' }} />
              </div>
              <div className='map mt-3'>
                <iframe class="uagb-google-map__iframe" title="Google Map for " src="https://maps.google.com/maps?q=Hamdan%20Heights%2C%20Islamabad%20Expressway%2C%20Phase%205%2C%20Ghauri%20Town%2C%20Islamabad%20Capital%20Territory%2C%2046000%20Pakistan&amp;z=12&amp;hl=en&amp;t=m&amp;output=embed&amp;iwloc=near" width="640" height="300" loading="lazy"></iframe>
              </div>
            </div>
          </div>
          <div className="div col-lg-6">
            <div className="left11">
              <h2 className='pakistan mb-2'>INDE</h2>
              <p className='immigria mb-1'>REDFORD</p>
              <p className='zafar mb-1'>Represented by Ms. Reeva MOOTOOSAMY</p>
              <div className="img1 mt-2">
                <img src="assets/imgs/a2.jpg" alt="" style={{ width: '462px', height: 'auto' }} />
              </div>
              <div className='map mt-2'>
                <iframe class="uagb-google-map__iframe" title="Google Map for " src="https://maps.google.com/maps?q=INDE&amp;z=12&amp;hl=fr&amp;t=m&amp;output=embed&amp;iwloc=near" width="640" height="300" loading="lazy"></iframe>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className="div col-lg-6">
            <div className="left11">
              <h2 className='pakistan mb-2'>IVORY COAST</h2>
              <p className='immigria mb-1'>NEA CONSULTING GROUP</p>
              <p className='zafar mb-1'>Represented by Mr. Jean Paulin NEA DOBA</p>
              <div className="img1 mt-2">
                <img src="assets/imgs/a1.jpg" alt="" style={{ width: '420px', height: 'auto' }} />
              </div>
              <div className='map mt-3'>
                <iframe class="uagb-google-map__iframe" title="Google Map for" src="https://maps.google.com/maps?q=Abidjan%20Cocody%2C%20C%C3%B4te%20d%E2%80%99Ivoire&amp;z=12&amp;hl=en&amp;t=m&amp;output=embed&amp;iwloc=near" width="640" height="300" loading="lazy"></iframe>
              </div>
            </div>
          </div>
          <div className="div col-lg-6">
            <div className="left11">
              <h2 className='pakistan mb-2'>SENEGAL</h2>
              <p className='immigria mb-1'>ISIS TRAVEL EXPRESS</p>
              <p className='zafar mb-1'>Represented by Ms. Aminata DIAME</p>
              <div className="img1 mt-2">
                <img src="assets/imgs/a3.jpg" alt="" style={{ width: '420px', height: 'auto' }} />
              </div>
              <div className='map mt-2'>
                <iframe class="uagb-google-map__iframe" title="Google Map for" src="https://maps.google.com/maps?q=HLM%204%20VILLA%20N%C2%B01689%20%E2%80%93%20DAKAR%2C%20S%C3%89N%C3%89GAL&amp;z=12&amp;hl=fr&amp;t=m&amp;output=embed&amp;iwloc=near" width="640" height="300" loading="lazy"></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ToastContainer />
    </div>
  );
};

export default Contact;
