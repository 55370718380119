import React from 'react'

const Blog = () => {
  return (
    <div className="page-wrapper"> 
    <div className="breadcrumb__area bg-color-2 p-relative" data-background="assets/imgs/page-title/page-title-2.jpg">
      <div className="shape-bg" data-background="assets/imgs/page-title/pt-bg.png" />
      <div className="shape-1" data-background="assets/imgs/page-title/pt-shape.png" />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="breadcrumb__wrapper p-relative">
              <div className="breadcrumb__menu">
                <nav>
                  <ul>
                    <li><span><a href="index.html">Home</a></span></li>
                    <li><span>Blog</span></li>
                  </ul>
                </nav>
              </div>
              <h2 className="breadcrumb__title">Blog</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section className="blog-section section-space p-relative">
      <div className="container">
        <div className="row g-4">
          <div className="col-lg-4">
            <div className="blog-style-1 p-relative">
              <a href="blog-details.html" className="w-img image">
                <img src="assets/imgs/blog/blog-1.jpg" alt="blog-image" />
                <img src="assets/imgs/blog/blog-1.jpg" alt="blog-image" />
              </a>
              <div className="content p-relative">
                <div className="date-excerpt">
                  <a href="#">16 Apr</a>
                </div>
                <div className="info-excerpt">
                  <div className="tags">
                    <svg width={15} height={16} viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_2032_2240)">
                        <path d="M13.621 0.497487C13.6125 0.497487 13.6041 0.497487 13.5957 0.497487C13.5882 0.497487 13.5816 0.496549 13.5741 0.497487H8.61754C8.4741 0.504987 8.14785 0.72905 8.10566 0.771237L2.13848 6.73749C1.77285 7.10311 1.77285 7.69561 2.13848 8.06124L2.88285 8.80561L2.13848 9.54999C1.77285 9.91561 1.77285 10.5081 2.13848 10.8737L6.4941 15.2294C6.67691 15.4122 6.91598 15.5031 7.15598 15.5031C7.39598 15.5031 7.63504 15.4122 7.81785 15.2294L13.7841 9.26218C13.8272 9.21999 14.0625 8.93124 14.0625 8.75124V5.93874V3.77593V0.963425C14.0757 0.701862 13.8779 0.497487 13.621 0.497487ZM12.6563 1.43124C12.915 1.43124 13.125 1.64124 13.125 1.89999C13.125 2.15874 12.915 2.36874 12.6563 2.36874C12.3975 2.36874 12.1875 2.15874 12.1875 1.89999C12.1875 1.64124 12.3975 1.43124 12.6563 1.43124ZM13.1222 8.6003L7.15785 14.5675L2.80223 10.2137L3.5466 9.46936L6.49504 12.4178C6.67785 12.6006 6.91691 12.6916 7.15691 12.6916C7.39691 12.6916 7.63598 12.6006 7.81879 12.4178L13.125 7.11061V8.5928C13.1222 8.59749 13.1204 8.6003 13.1222 8.6003C13.1213 8.6003 13.1213 8.6003 13.1222 8.6003Z" fill="#E13833" />
                      </g>
                      <defs>
                        <clipPath>
                          <rect width={15} height="15.0066" fill="white" transform="translate(0 0.49707)" />
                        </clipPath>
                      </defs>
                    </svg>
                    <a href="#">Design</a>
                  </div>
                  <div className="comments">
                    <svg width={15} height={16} viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.88844 2.29862C7.95694 1.72411 6.85313 1.4082 5.71289 1.4082C2.609 1.4082 0 3.72069 0 6.68164C0 7.71978 0.3225 8.71496 0.935098 9.57444L0.0793359 12.2611C-0.0110742 12.5449 0.201504 12.834 0.498135 12.834C0.565957 12.834 0.634219 12.8183 0.697207 12.7863L3.29971 11.463C3.40509 11.5084 3.51179 11.5506 3.61957 11.5898C3.01702 10.6493 2.69531 9.56973 2.69531 8.43945C2.69531 5.07333 5.52768 2.48551 8.88844 2.29862Z" fill="#5D666F" />
                      <path d="M14.0649 11.3323C14.6775 10.4728 15 9.47759 15 8.43945C15 5.47742 12.3899 3.16602 9.28711 3.16602C6.18322 3.16602 3.57422 5.47851 3.57422 8.43945C3.57422 11.4015 6.18434 13.7129 9.28711 13.7129C10.1196 13.7129 10.9492 13.5432 11.7001 13.2208L14.3028 14.5441C14.4607 14.6244 14.6515 14.602 14.7865 14.4872C14.9216 14.3725 14.9745 14.1878 14.9207 14.019L14.0649 11.3323ZM7.5 8.87891C7.2573 8.87891 7.06055 8.68215 7.06055 8.43945C7.06055 8.19676 7.2573 8 7.5 8C7.7427 8 7.93945 8.19676 7.93945 8.43945C7.93945 8.68215 7.7427 8.87891 7.5 8.87891ZM9.25781 8.87891C9.01512 8.87891 8.81836 8.68215 8.81836 8.43945C8.81836 8.19676 9.01512 8 9.25781 8C9.50051 8 9.69727 8.19676 9.69727 8.43945C9.69727 8.68215 9.50051 8.87891 9.25781 8.87891ZM11.0156 8.87891C10.7729 8.87891 10.5762 8.68215 10.5762 8.43945C10.5762 8.19676 10.7729 8 11.0156 8C11.2583 8 11.4551 8.19676 11.4551 8.43945C11.4551 8.68215 11.2583 8.87891 11.0156 8.87891Z" fill="#E13833" />
                    </svg>
                    <a href="#">2 Comments</a>
                  </div> 
                </div>
                <h4 className="mt-10 mb-20"> <a href="blog-details.html">Unsatiable Entreaties May Collecting Power.</a></h4>
                <a href="blog-details.html" className="blog-btn"><i className="icon-4" /></a>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="blog-style-1 p-relative">
              <a href="blog-details.html" className="w-img image">
                <img src="assets/imgs/blog/blog-2.jpg" alt="blog-image" />
                <img src="assets/imgs/blog/blog-2.jpg" alt="blog-image" />
              </a>
              <div className="content p-relative">
                <div className="date-excerpt">
                  <a href="#">16 Apr</a>
                </div>
                <div className="info-excerpt">
                  <div className="tags">
                    <svg width={15} height={16} viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_2032_2240)">
                        <path d="M13.621 0.497487C13.6125 0.497487 13.6041 0.497487 13.5957 0.497487C13.5882 0.497487 13.5816 0.496549 13.5741 0.497487H8.61754C8.4741 0.504987 8.14785 0.72905 8.10566 0.771237L2.13848 6.73749C1.77285 7.10311 1.77285 7.69561 2.13848 8.06124L2.88285 8.80561L2.13848 9.54999C1.77285 9.91561 1.77285 10.5081 2.13848 10.8737L6.4941 15.2294C6.67691 15.4122 6.91598 15.5031 7.15598 15.5031C7.39598 15.5031 7.63504 15.4122 7.81785 15.2294L13.7841 9.26218C13.8272 9.21999 14.0625 8.93124 14.0625 8.75124V5.93874V3.77593V0.963425C14.0757 0.701862 13.8779 0.497487 13.621 0.497487ZM12.6563 1.43124C12.915 1.43124 13.125 1.64124 13.125 1.89999C13.125 2.15874 12.915 2.36874 12.6563 2.36874C12.3975 2.36874 12.1875 2.15874 12.1875 1.89999C12.1875 1.64124 12.3975 1.43124 12.6563 1.43124ZM13.1222 8.6003L7.15785 14.5675L2.80223 10.2137L3.5466 9.46936L6.49504 12.4178C6.67785 12.6006 6.91691 12.6916 7.15691 12.6916C7.39691 12.6916 7.63598 12.6006 7.81879 12.4178L13.125 7.11061V8.5928C13.1222 8.59749 13.1204 8.6003 13.1222 8.6003C13.1213 8.6003 13.1213 8.6003 13.1222 8.6003Z" fill="#E13833" />
                      </g>
                      <defs>
                        <clipPath>
                          <rect width={15} height="15.0066" fill="white" transform="translate(0 0.49707)" />
                        </clipPath>
                      </defs>
                    </svg>
                    <a href="#">Business</a>
                  </div>
                  <div className="comments">
                    <svg width={15} height={16} viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.88844 2.29862C7.95694 1.72411 6.85313 1.4082 5.71289 1.4082C2.609 1.4082 0 3.72069 0 6.68164C0 7.71978 0.3225 8.71496 0.935098 9.57444L0.0793359 12.2611C-0.0110742 12.5449 0.201504 12.834 0.498135 12.834C0.565957 12.834 0.634219 12.8183 0.697207 12.7863L3.29971 11.463C3.40509 11.5084 3.51179 11.5506 3.61957 11.5898C3.01702 10.6493 2.69531 9.56973 2.69531 8.43945C2.69531 5.07333 5.52768 2.48551 8.88844 2.29862Z" fill="#5D666F" />
                      <path d="M14.0649 11.3323C14.6775 10.4728 15 9.47759 15 8.43945C15 5.47742 12.3899 3.16602 9.28711 3.16602C6.18322 3.16602 3.57422 5.47851 3.57422 8.43945C3.57422 11.4015 6.18434 13.7129 9.28711 13.7129C10.1196 13.7129 10.9492 13.5432 11.7001 13.2208L14.3028 14.5441C14.4607 14.6244 14.6515 14.602 14.7865 14.4872C14.9216 14.3725 14.9745 14.1878 14.9207 14.019L14.0649 11.3323ZM7.5 8.87891C7.2573 8.87891 7.06055 8.68215 7.06055 8.43945C7.06055 8.19676 7.2573 8 7.5 8C7.7427 8 7.93945 8.19676 7.93945 8.43945C7.93945 8.68215 7.7427 8.87891 7.5 8.87891ZM9.25781 8.87891C9.01512 8.87891 8.81836 8.68215 8.81836 8.43945C8.81836 8.19676 9.01512 8 9.25781 8C9.50051 8 9.69727 8.19676 9.69727 8.43945C9.69727 8.68215 9.50051 8.87891 9.25781 8.87891ZM11.0156 8.87891C10.7729 8.87891 10.5762 8.68215 10.5762 8.43945C10.5762 8.19676 10.7729 8 11.0156 8C11.2583 8 11.4551 8.19676 11.4551 8.43945C11.4551 8.68215 11.2583 8.87891 11.0156 8.87891Z" fill="#E13833" />
                    </svg>
                    <a href="#">3 Comments</a>
                  </div> 
                </div>
                <h4 className="mt-10 mb-20"> <a href="blog-details.html">It’s through refresing to get such a personal </a></h4>
                <a href="blog-details.html" className="blog-btn"><i className="icon-4" /></a>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="blog-style-1 p-relative">
              <a href="blog-details.html" className="w-img image">
                <img src="assets/imgs/blog/blog-3.jpg" alt="blog-image" />
                <img src="assets/imgs/blog/blog-3.jpg" alt="blog-image" />
              </a>
              <div className="content p-relative">
                <div className="date-excerpt">
                  <a href="#">18 Apr</a>
                </div>
                <div className="info-excerpt">
                  <div className="tags">
                    <svg width={15} height={16} viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_2032_2240)">
                        <path d="M13.621 0.497487C13.6125 0.497487 13.6041 0.497487 13.5957 0.497487C13.5882 0.497487 13.5816 0.496549 13.5741 0.497487H8.61754C8.4741 0.504987 8.14785 0.72905 8.10566 0.771237L2.13848 6.73749C1.77285 7.10311 1.77285 7.69561 2.13848 8.06124L2.88285 8.80561L2.13848 9.54999C1.77285 9.91561 1.77285 10.5081 2.13848 10.8737L6.4941 15.2294C6.67691 15.4122 6.91598 15.5031 7.15598 15.5031C7.39598 15.5031 7.63504 15.4122 7.81785 15.2294L13.7841 9.26218C13.8272 9.21999 14.0625 8.93124 14.0625 8.75124V5.93874V3.77593V0.963425C14.0757 0.701862 13.8779 0.497487 13.621 0.497487ZM12.6563 1.43124C12.915 1.43124 13.125 1.64124 13.125 1.89999C13.125 2.15874 12.915 2.36874 12.6563 2.36874C12.3975 2.36874 12.1875 2.15874 12.1875 1.89999C12.1875 1.64124 12.3975 1.43124 12.6563 1.43124ZM13.1222 8.6003L7.15785 14.5675L2.80223 10.2137L3.5466 9.46936L6.49504 12.4178C6.67785 12.6006 6.91691 12.6916 7.15691 12.6916C7.39691 12.6916 7.63598 12.6006 7.81879 12.4178L13.125 7.11061V8.5928C13.1222 8.59749 13.1204 8.6003 13.1222 8.6003C13.1213 8.6003 13.1213 8.6003 13.1222 8.6003Z" fill="#E13833" />
                      </g>
                      <defs>
                        <clipPath>
                          <rect width={15} height="15.0066" fill="white" transform="translate(0 0.49707)" />
                        </clipPath>
                      </defs>
                    </svg>
                    <a href="#">Design</a>
                  </div>
                  <div className="comments">
                    <svg width={15} height={16} viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.88844 2.29862C7.95694 1.72411 6.85313 1.4082 5.71289 1.4082C2.609 1.4082 0 3.72069 0 6.68164C0 7.71978 0.3225 8.71496 0.935098 9.57444L0.0793359 12.2611C-0.0110742 12.5449 0.201504 12.834 0.498135 12.834C0.565957 12.834 0.634219 12.8183 0.697207 12.7863L3.29971 11.463C3.40509 11.5084 3.51179 11.5506 3.61957 11.5898C3.01702 10.6493 2.69531 9.56973 2.69531 8.43945C2.69531 5.07333 5.52768 2.48551 8.88844 2.29862Z" fill="#5D666F" />
                      <path d="M14.0649 11.3323C14.6775 10.4728 15 9.47759 15 8.43945C15 5.47742 12.3899 3.16602 9.28711 3.16602C6.18322 3.16602 3.57422 5.47851 3.57422 8.43945C3.57422 11.4015 6.18434 13.7129 9.28711 13.7129C10.1196 13.7129 10.9492 13.5432 11.7001 13.2208L14.3028 14.5441C14.4607 14.6244 14.6515 14.602 14.7865 14.4872C14.9216 14.3725 14.9745 14.1878 14.9207 14.019L14.0649 11.3323ZM7.5 8.87891C7.2573 8.87891 7.06055 8.68215 7.06055 8.43945C7.06055 8.19676 7.2573 8 7.5 8C7.7427 8 7.93945 8.19676 7.93945 8.43945C7.93945 8.68215 7.7427 8.87891 7.5 8.87891ZM9.25781 8.87891C9.01512 8.87891 8.81836 8.68215 8.81836 8.43945C8.81836 8.19676 9.01512 8 9.25781 8C9.50051 8 9.69727 8.19676 9.69727 8.43945C9.69727 8.68215 9.50051 8.87891 9.25781 8.87891ZM11.0156 8.87891C10.7729 8.87891 10.5762 8.68215 10.5762 8.43945C10.5762 8.19676 10.7729 8 11.0156 8C11.2583 8 11.4551 8.19676 11.4551 8.43945C11.4551 8.68215 11.2583 8.87891 11.0156 8.87891Z" fill="#E13833" />
                    </svg>
                    <a href="#">No Comments</a>
                  </div> 
                </div>
                <h4 className="mt-10 mb-20"> <a href="blog-details.html">Due to their excellent service competitive pricing </a></h4>
                <a href="blog-details.html" className="blog-btn"><i className="icon-4" /></a>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="blog-style-1 p-relative">
              <a href="blog-details.html" className="w-img image">
                <img src="assets/imgs/blog/blog-5.jpg" alt="blog-image" />
                <img src="assets/imgs/blog/blog-5.jpg" alt="blog-image" />
              </a>
              <div className="content p-relative">
                <div className="date-excerpt">
                  <a href="#">18 Apr</a>
                </div>
                <div className="info-excerpt">
                  <div className="tags">
                    <svg width={15} height={16} viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_2032_2240)">
                        <path d="M13.621 0.497487C13.6125 0.497487 13.6041 0.497487 13.5957 0.497487C13.5882 0.497487 13.5816 0.496549 13.5741 0.497487H8.61754C8.4741 0.504987 8.14785 0.72905 8.10566 0.771237L2.13848 6.73749C1.77285 7.10311 1.77285 7.69561 2.13848 8.06124L2.88285 8.80561L2.13848 9.54999C1.77285 9.91561 1.77285 10.5081 2.13848 10.8737L6.4941 15.2294C6.67691 15.4122 6.91598 15.5031 7.15598 15.5031C7.39598 15.5031 7.63504 15.4122 7.81785 15.2294L13.7841 9.26218C13.8272 9.21999 14.0625 8.93124 14.0625 8.75124V5.93874V3.77593V0.963425C14.0757 0.701862 13.8779 0.497487 13.621 0.497487ZM12.6563 1.43124C12.915 1.43124 13.125 1.64124 13.125 1.89999C13.125 2.15874 12.915 2.36874 12.6563 2.36874C12.3975 2.36874 12.1875 2.15874 12.1875 1.89999C12.1875 1.64124 12.3975 1.43124 12.6563 1.43124ZM13.1222 8.6003L7.15785 14.5675L2.80223 10.2137L3.5466 9.46936L6.49504 12.4178C6.67785 12.6006 6.91691 12.6916 7.15691 12.6916C7.39691 12.6916 7.63598 12.6006 7.81879 12.4178L13.125 7.11061V8.5928C13.1222 8.59749 13.1204 8.6003 13.1222 8.6003C13.1213 8.6003 13.1213 8.6003 13.1222 8.6003Z" fill="#E13833" />
                      </g>
                      <defs>
                        <clipPath>
                          <rect width={15} height="15.0066" fill="white" transform="translate(0 0.49707)" />
                        </clipPath>
                      </defs>
                    </svg>
                    <a href="#">Design</a>
                  </div>
                  <div className="comments">
                    <svg width={15} height={16} viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.88844 2.29862C7.95694 1.72411 6.85313 1.4082 5.71289 1.4082C2.609 1.4082 0 3.72069 0 6.68164C0 7.71978 0.3225 8.71496 0.935098 9.57444L0.0793359 12.2611C-0.0110742 12.5449 0.201504 12.834 0.498135 12.834C0.565957 12.834 0.634219 12.8183 0.697207 12.7863L3.29971 11.463C3.40509 11.5084 3.51179 11.5506 3.61957 11.5898C3.01702 10.6493 2.69531 9.56973 2.69531 8.43945C2.69531 5.07333 5.52768 2.48551 8.88844 2.29862Z" fill="#5D666F" />
                      <path d="M14.0649 11.3323C14.6775 10.4728 15 9.47759 15 8.43945C15 5.47742 12.3899 3.16602 9.28711 3.16602C6.18322 3.16602 3.57422 5.47851 3.57422 8.43945C3.57422 11.4015 6.18434 13.7129 9.28711 13.7129C10.1196 13.7129 10.9492 13.5432 11.7001 13.2208L14.3028 14.5441C14.4607 14.6244 14.6515 14.602 14.7865 14.4872C14.9216 14.3725 14.9745 14.1878 14.9207 14.019L14.0649 11.3323ZM7.5 8.87891C7.2573 8.87891 7.06055 8.68215 7.06055 8.43945C7.06055 8.19676 7.2573 8 7.5 8C7.7427 8 7.93945 8.19676 7.93945 8.43945C7.93945 8.68215 7.7427 8.87891 7.5 8.87891ZM9.25781 8.87891C9.01512 8.87891 8.81836 8.68215 8.81836 8.43945C8.81836 8.19676 9.01512 8 9.25781 8C9.50051 8 9.69727 8.19676 9.69727 8.43945C9.69727 8.68215 9.50051 8.87891 9.25781 8.87891ZM11.0156 8.87891C10.7729 8.87891 10.5762 8.68215 10.5762 8.43945C10.5762 8.19676 10.7729 8 11.0156 8C11.2583 8 11.4551 8.19676 11.4551 8.43945C11.4551 8.68215 11.2583 8.87891 11.0156 8.87891Z" fill="#E13833" />
                    </svg>
                    <a href="#">No Comments</a>
                  </div> 
                </div>
                <h4 className="mt-10 mb-20"> <a href="blog-details.html">when met with resistance, for accumulating power. </a></h4>
                <a href="blog-details.html" className="blog-btn"><i className="icon-4" /></a>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="blog-style-1 p-relative">
              <a href="blog-details.html" className="w-img image">
                <img src="assets/imgs/blog/blog-6.jpg" alt="blog-image" />
                <img src="assets/imgs/blog/blog-6.jpg" alt="blog-image" />
              </a>
              <div className="content p-relative">
                <div className="date-excerpt">
                  <a href="#">18 Apr</a>
                </div>
                <div className="info-excerpt">
                  <div className="tags">
                    <svg width={15} height={16} viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_2032_2240)">
                        <path d="M13.621 0.497487C13.6125 0.497487 13.6041 0.497487 13.5957 0.497487C13.5882 0.497487 13.5816 0.496549 13.5741 0.497487H8.61754C8.4741 0.504987 8.14785 0.72905 8.10566 0.771237L2.13848 6.73749C1.77285 7.10311 1.77285 7.69561 2.13848 8.06124L2.88285 8.80561L2.13848 9.54999C1.77285 9.91561 1.77285 10.5081 2.13848 10.8737L6.4941 15.2294C6.67691 15.4122 6.91598 15.5031 7.15598 15.5031C7.39598 15.5031 7.63504 15.4122 7.81785 15.2294L13.7841 9.26218C13.8272 9.21999 14.0625 8.93124 14.0625 8.75124V5.93874V3.77593V0.963425C14.0757 0.701862 13.8779 0.497487 13.621 0.497487ZM12.6563 1.43124C12.915 1.43124 13.125 1.64124 13.125 1.89999C13.125 2.15874 12.915 2.36874 12.6563 2.36874C12.3975 2.36874 12.1875 2.15874 12.1875 1.89999C12.1875 1.64124 12.3975 1.43124 12.6563 1.43124ZM13.1222 8.6003L7.15785 14.5675L2.80223 10.2137L3.5466 9.46936L6.49504 12.4178C6.67785 12.6006 6.91691 12.6916 7.15691 12.6916C7.39691 12.6916 7.63598 12.6006 7.81879 12.4178L13.125 7.11061V8.5928C13.1222 8.59749 13.1204 8.6003 13.1222 8.6003C13.1213 8.6003 13.1213 8.6003 13.1222 8.6003Z" fill="#E13833" />
                      </g>
                      <defs>
                        <clipPath>
                          <rect width={15} height="15.0066" fill="white" transform="translate(0 0.49707)" />
                        </clipPath>
                      </defs>
                    </svg>
                    <a href="#">Design</a>
                  </div>
                  <div className="comments">
                    <svg width={15} height={16} viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.88844 2.29862C7.95694 1.72411 6.85313 1.4082 5.71289 1.4082C2.609 1.4082 0 3.72069 0 6.68164C0 7.71978 0.3225 8.71496 0.935098 9.57444L0.0793359 12.2611C-0.0110742 12.5449 0.201504 12.834 0.498135 12.834C0.565957 12.834 0.634219 12.8183 0.697207 12.7863L3.29971 11.463C3.40509 11.5084 3.51179 11.5506 3.61957 11.5898C3.01702 10.6493 2.69531 9.56973 2.69531 8.43945C2.69531 5.07333 5.52768 2.48551 8.88844 2.29862Z" fill="#5D666F" />
                      <path d="M14.0649 11.3323C14.6775 10.4728 15 9.47759 15 8.43945C15 5.47742 12.3899 3.16602 9.28711 3.16602C6.18322 3.16602 3.57422 5.47851 3.57422 8.43945C3.57422 11.4015 6.18434 13.7129 9.28711 13.7129C10.1196 13.7129 10.9492 13.5432 11.7001 13.2208L14.3028 14.5441C14.4607 14.6244 14.6515 14.602 14.7865 14.4872C14.9216 14.3725 14.9745 14.1878 14.9207 14.019L14.0649 11.3323ZM7.5 8.87891C7.2573 8.87891 7.06055 8.68215 7.06055 8.43945C7.06055 8.19676 7.2573 8 7.5 8C7.7427 8 7.93945 8.19676 7.93945 8.43945C7.93945 8.68215 7.7427 8.87891 7.5 8.87891ZM9.25781 8.87891C9.01512 8.87891 8.81836 8.68215 8.81836 8.43945C8.81836 8.19676 9.01512 8 9.25781 8C9.50051 8 9.69727 8.19676 9.69727 8.43945C9.69727 8.68215 9.50051 8.87891 9.25781 8.87891ZM11.0156 8.87891C10.7729 8.87891 10.5762 8.68215 10.5762 8.43945C10.5762 8.19676 10.7729 8 11.0156 8C11.2583 8 11.4551 8.19676 11.4551 8.43945C11.4551 8.68215 11.2583 8.87891 11.0156 8.87891Z" fill="#E13833" />
                    </svg>
                    <a href="#">No Comments</a>
                  </div> 
                </div>
                <h4 className="mt-10 mb-20"> <a href="blog-details.html">gained builds gradually through persistent entreaties. </a></h4>
                <a href="blog-details.html" className="blog-btn"><i className="icon-4" /></a>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="blog-style-1 p-relative">
              <a href="blog-details.html" className="w-img image">
                <img src="assets/imgs/blog/blog-7.jpg" alt="blog-image" />
                <img src="assets/imgs/blog/blog-7.jpg" alt="blog-image" />
              </a>
              <div className="content p-relative">
                <div className="date-excerpt">
                  <a href="#">18 Apr</a>
                </div>
                <div className="info-excerpt">
                  <div className="tags">
                    <svg width={15} height={16} viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_2032_2240)">
                        <path d="M13.621 0.497487C13.6125 0.497487 13.6041 0.497487 13.5957 0.497487C13.5882 0.497487 13.5816 0.496549 13.5741 0.497487H8.61754C8.4741 0.504987 8.14785 0.72905 8.10566 0.771237L2.13848 6.73749C1.77285 7.10311 1.77285 7.69561 2.13848 8.06124L2.88285 8.80561L2.13848 9.54999C1.77285 9.91561 1.77285 10.5081 2.13848 10.8737L6.4941 15.2294C6.67691 15.4122 6.91598 15.5031 7.15598 15.5031C7.39598 15.5031 7.63504 15.4122 7.81785 15.2294L13.7841 9.26218C13.8272 9.21999 14.0625 8.93124 14.0625 8.75124V5.93874V3.77593V0.963425C14.0757 0.701862 13.8779 0.497487 13.621 0.497487ZM12.6563 1.43124C12.915 1.43124 13.125 1.64124 13.125 1.89999C13.125 2.15874 12.915 2.36874 12.6563 2.36874C12.3975 2.36874 12.1875 2.15874 12.1875 1.89999C12.1875 1.64124 12.3975 1.43124 12.6563 1.43124ZM13.1222 8.6003L7.15785 14.5675L2.80223 10.2137L3.5466 9.46936L6.49504 12.4178C6.67785 12.6006 6.91691 12.6916 7.15691 12.6916C7.39691 12.6916 7.63598 12.6006 7.81879 12.4178L13.125 7.11061V8.5928C13.1222 8.59749 13.1204 8.6003 13.1222 8.6003C13.1213 8.6003 13.1213 8.6003 13.1222 8.6003Z" fill="#E13833" />
                      </g>
                      <defs>
                        <clipPath>
                          <rect width={15} height="15.0066" fill="white" transform="translate(0 0.49707)" />
                        </clipPath>
                      </defs>
                    </svg>
                    <a href="#">Design</a>
                  </div>
                  <div className="comments">
                    <svg width={15} height={16} viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.88844 2.29862C7.95694 1.72411 6.85313 1.4082 5.71289 1.4082C2.609 1.4082 0 3.72069 0 6.68164C0 7.71978 0.3225 8.71496 0.935098 9.57444L0.0793359 12.2611C-0.0110742 12.5449 0.201504 12.834 0.498135 12.834C0.565957 12.834 0.634219 12.8183 0.697207 12.7863L3.29971 11.463C3.40509 11.5084 3.51179 11.5506 3.61957 11.5898C3.01702 10.6493 2.69531 9.56973 2.69531 8.43945C2.69531 5.07333 5.52768 2.48551 8.88844 2.29862Z" fill="#5D666F" />
                      <path d="M14.0649 11.3323C14.6775 10.4728 15 9.47759 15 8.43945C15 5.47742 12.3899 3.16602 9.28711 3.16602C6.18322 3.16602 3.57422 5.47851 3.57422 8.43945C3.57422 11.4015 6.18434 13.7129 9.28711 13.7129C10.1196 13.7129 10.9492 13.5432 11.7001 13.2208L14.3028 14.5441C14.4607 14.6244 14.6515 14.602 14.7865 14.4872C14.9216 14.3725 14.9745 14.1878 14.9207 14.019L14.0649 11.3323ZM7.5 8.87891C7.2573 8.87891 7.06055 8.68215 7.06055 8.43945C7.06055 8.19676 7.2573 8 7.5 8C7.7427 8 7.93945 8.19676 7.93945 8.43945C7.93945 8.68215 7.7427 8.87891 7.5 8.87891ZM9.25781 8.87891C9.01512 8.87891 8.81836 8.68215 8.81836 8.43945C8.81836 8.19676 9.01512 8 9.25781 8C9.50051 8 9.69727 8.19676 9.69727 8.43945C9.69727 8.68215 9.50051 8.87891 9.25781 8.87891ZM11.0156 8.87891C10.7729 8.87891 10.5762 8.68215 10.5762 8.43945C10.5762 8.19676 10.7729 8 11.0156 8C11.2583 8 11.4551 8.19676 11.4551 8.43945C11.4551 8.68215 11.2583 8.87891 11.0156 8.87891Z" fill="#E13833" />
                    </svg>
                    <a href="#">No Comments</a>
                  </div> 
                </div>
                <h4 className="mt-10 mb-20"> <a href="blog-details.html"> that each appeal adds to influence or power. </a></h4>
                <a href="blog-details.html" className="blog-btn"><i className="icon-4" /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  )
}

export default Blog