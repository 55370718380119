
import React, { useEffect } from 'react';
function Footer() {
  const currentYear = new Date().getFullYear();


  return (

    <footer>

      <div className="footer-main p-relative bg-color fix">
        <div className="shape-1" data-background="assets/imgs/footer/footer-shape-1.png" />

        <div className="container">
          <div className="footer-upper pt-80 pb-50">
            <div className="row">
              <div className="col-lg-4">
                <div className="footer-widget-1 p-relative">
                  <a href="index.html" className="fw-title"><img src="assets/imgs/logo/footer-logo.png" alt="logo" /></a>
                  <span>Immigra</span>
                  <p>Immigra Consultants Private Limited is your trusted partner for navigating the Canadian immigration process</p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="footer-widget-1 p-relative">
                  <h5 className="fw-title">Contact Info</h5>
                  <ul className="contact-info mb-25">
                    <li><a href="tel:+88123456987231"><i className="icon-36" />+88 12345 6987 231</a></li>
                    <li><a href="mailto:info@envato.com"><i className="icon-74" />info@immigraconsultants.com</a></li>
                    <li><a href="https://maps.app.goo.gl/yQLW4ejK23EDobHs5" target="_blank"><i className="icon-35" />Office No 06/Ground Floor,Hamdan Heights,ExpressWay ,ISB</a></li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="footer-widget-1 p-relative">
                  <h5 className="fw-title">Open Time</h5>
                  <div className="time-area">
                    <div className="one mb-30">
                      <h5>Monday - Friday: </h5>
                      <span>10.00 am to 08.00 pm</span>
                    </div>
                    <div className="tow">
                      <h5>Saturday: </h5>
                      <span>11.00 am to 10.00 pm</span>
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>
          <div className="footer-bottom pt-20 pb-20 text-center p-relative">
            <h6>© {currentYear} <a href="/">ImmiGrants Canada</a>, All Rights Reserved</h6>
          </div>
        </div>
      </div>



    </footer>




  )
}

export default Footer