
import { Link } from 'react-router-dom';
import React, { useState } from 'react'
import './topnav.css';
import Loader from '../../utils/Loader';

export default function TopNav() {

  const [isOpen, setIsOpen] = useState(false);
  const handleToggleClick = () => {
    setIsOpen(prevState => !prevState);
  };
  const handleClick1 = () => {
    setIsOpen(false)
  }
  const [activeItem, setActiveItem] = useState('');
  const handleClick = (item) => {
    setActiveItem(item);
  };
  return (
    <>
      <Loader />
      {/*Mobile Menu */}
      <div className="fix">
        <div className={`offcanvas__info ${isOpen ? 'info-open' : ''}`}  >
          <div className="offcanvas__wrapper">
            <div className="offcanvas__content">
              <div className="offcanvas__top mb-10 d-flex justify-content-between align-items-center">
                <div className="offcanvas__logo">
                  <Link to="/">
                    <img src="assets/imgs/logo/footer-logo.png" alt="Header Logo" />
                    <p style={{color:"#ffff", fontSize:'18px' }}>Immigra</p>

                  </Link>
                </div>
                <div className="offcanvas__close" onClick={handleClick1}>
                  <button>
                    <i className="fal fa-times" />
                  </button>
                </div>
              </div>
              <div className="offcanvas__search mb-25">
                <p className="text-white">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ea numquam velit dolore quos deleniti saepe dolores, perspiciatis porro fuga quas provident voluptatum esse quae nulla atque reprehenderit magnam, dolor exercitationem.</p>
              </div>
              <div className="mobile-menu fix mb-40" >
                <div className="mobile-menu fix mb-40 mean-container"><div className="mean-bar">
                  <Link to="#nav" className="meanmenu-reveal" style={{ right: 0, left: 'auto', display: 'inline' }}><span><span><span /></span></span></Link><nav className="mean-nav">
                    <ul style={{ display: 'none' }}>
                      <li className="mean-last offcanvas__close">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="mean-last offcanvas__close">
                        <Link to="/about">About Us</Link>
                      </li>
                      <li className="mean-last offcanvas__close">
                        <Link to="/testimonials">Testimonials</Link>
                      </li>
                      <li className="mean-last offcanvas__close">
                        <Link to="/blog">Blog</Link>
                      </li>
                      <li className="mean-last offcanvas__close">
                        <Link to="/contact ">Contact</Link>
                      </li>
                    </ul>
                  </nav>
                </div>
                </div>
              </div>
              <div className="offcanvas__contact mb-20">
                <h4>Contact Info</h4>
                <ul>
                  <li className="d-flex align-items-center">
                    <div className="offcanvas__contact-icon mr-15">
                      <i className="fal fa-map-marker-alt" />
                    </div>
                    <div className="offcanvas__contact-text">
                      <Link target="_blank" to="#">Office No 06/Ground Floor,Hamdan Heights,ExpressWay ,ISB</Link>
                    </div>
                  </li>
                  <li className="d-flex align-items-center">
                    <div className="offcanvas__contact-icon mr-15">
                      <i className="far fa-phone" />
                    </div>
                    <div className="offcanvas__contact-text">
                      <Link to="tel:+00112233665">+00112233665</Link>
                    </div>
                  </li>
                  <li className="d-flex align-items-center">
                    <div className="offcanvas__contact-icon mr-15">
                      <i className="fal fa-envelope" />
                    </div>
                    <div className="offcanvas__contact-text">
                      <Link to="/"><span className="mailto:info@immigraconsultants.com">info@immigraconsultants.com</span></Link>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="offcanvas__input mt-30 mb-30">
                <div className="offcanvas__contact">
                  <h4>Get Update</h4>
                </div>
                <form action="#">
                  <div className="p-relative">
                    <input type="text" placeholder="Enter mail" />
                    <button>
                      <i className="fas fa-paper-plane" />
                    </button>
                  </div>
                </form>
              </div>
              <div className="offcanvas__social">
                <ul>
                  <li> <Link to="#"><i className="fab fa-facebook-f" /></Link></li>
                  <li> <Link to="#"><i className="fab fa-twitter" /></Link></li>
                  <li> <Link to="#"><i className="fab fa-youtube" /></Link></li>
                  <li> <Link to="#"><i className="fab fa-linkedin-in" /></Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*Mobile Menu End */}
      <div className="offcanvas__overlay-white"></div>
      <header className="header-one p-relative">
        <div className="header-top bg-color-1">
          <div className="container-fluid g-0 p-0">
            <div className="header-info">
              <ul className="d-flex">
                <li><i className="icon-1" />info@immigraconsultants.com</li>
                <li><i className="icon-2" /> Office No 06/Ground Floor,Hamdan Heights,ExpressWay ,ISB</li>
                <li><i className="icon-3" /> Mon - Fri  / 9:30AM - 6:30PM </li>
              </ul>
            </div>
            <div className="socials-info bg-color-2">
              <ul className="two d-flex">
                <li><Link><i className="icon-41" /></Link></li>
                <li><Link><i className="icon-42" /></Link></li>
                <li><Link><i className="icon-43" /></Link></li>
                <li><Link><i className="icon-44" /></Link></li>
              </ul>
            </div>
          </div>
        </div>
        <div id="header-sticky" className="header-area">
          <div className="header-box-main">
            <div className="container-fluid g-0 p-0">
              <div className="mega-menu-wrapper">
                <div className="header-main">
                  <div className="header-left">
                    <div className="header-logo">
                      <Link to="/">
                        <img src="assets/imgs/logo/logo.svg" alt="header logo" style={{ width: 200 }} />
                      </Link>
                    </div>
                    <div className="mean__menu-wrapper d-none d-lg-block">
                      <div className="main-menu">
                        <nav id="mobile-menu">
                          <ul>
                            <li className="has-dropdown active">
                              <Link to="/" onClick={() => handleClick('Home')} style={{
                                cursor: 'pointer',
                                color: activeItem === 'Home' ? 'rgb(216,27,61,100%)' : '#000000',
                              }}>Home
                              </Link>
                            </li>
                            <li><Link to="/about" onClick={() => handleClick('About')} style={{
                              cursor: 'pointer',
                              color: activeItem === 'About' ? 'rgb(216,27,61,100%)' : '#000000',
                            }}>About Us</Link>
                            </li>
                            <li><Link to="/testimonials" onClick={() => handleClick('testimonials')} style={{
                              cursor: 'pointer',
                              color: activeItem === 'testimonials' ? 'rgb(216,27,61,100%)' : '#000000',
                            }}>Testimonials</Link>
                            </li>
                            <li><Link to="/our-services" onClick={() => handleClick('our-services')} style={{
                              cursor: 'pointer',
                              color: activeItem === 'our-services' ? 'rgb(216,27,61,100%)' : '#000000',
                            }}>Our Services</Link>
                            </li>
                            <li className="has-dropdown">
                              <Link to="/blog" onClick={() => handleClick('blog')} style={{
                                cursor: 'pointer',
                                color: activeItem === 'blog' ? 'rgb(216,27,61,100%)' : '#000000',
                              }}>Blog</Link>
                            </li>
                            <li>
                              <Link to="/contact" onClick={() => handleClick('contact')} style={{
                                cursor: 'pointer',
                                color: activeItem === 'contact' ? 'rgb(216,27,61,100%)' : '#000000',
                              }}> Contact</Link>
                            </li>
                          </ul>
                        </nav>
                        {/* for wp */}
                        <div className="header__hamburger ml-50 d-none">
                          <button type="button" className="hamburger-btn offcanvas-open-btn">
                            <span>01</span>
                            <span>01</span>
                            <span>01</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="header-right d-flex align-items-center">
                    <div className="header__hamburger d-inline-flex">
                      <div className="sidebar__toggle" onClick={handleToggleClick}>
                        <Link to="#" style={{ color: '#000000' }}>
                          <i className="icon-45" />
                        </Link>
                      </div>
                    </div>
                    <div className="header-action d-xl-inline-flex d-none">
                      <div className="header-link">
                        <Link className="primary-btn-2 btn-hover" to="/contact">
                          Book Appointment
                          <span style={{ top: '147.172px', left: '108.5px' }} />
                        </Link>
                      </div>
                    </div>
                    <div className="header-call-us d-xl-inline-flex d-none">
                      <div className="icon-box">
                        <svg width={53} height={53} viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M31.4058 6.35513C28.1145 4.32519 24.2144 3.20898 20.1855 3.20898C9.21848 3.20898 0 11.3798 0 21.8418C0 25.5099 1.1395 29.0262 3.30401 32.063L0.28032 41.556C-0.0391289 42.5588 0.711981 43.5801 1.76008 43.5801C1.99971 43.5801 2.24091 43.5247 2.46346 43.4115L11.659 38.736C12.0313 38.8963 12.4083 39.0455 12.7891 39.184C10.6601 35.8609 9.52344 32.0464 9.52344 28.0527C9.52344 16.1591 19.5311 7.01546 31.4058 6.35513Z" fill="#E13833" />
                          <path d="M49.696 38.274C51.8605 35.2371 53 31.7208 53 28.0527C53 17.5869 43.7776 9.41992 32.8145 9.41992C21.8474 9.41992 12.6289 17.5907 12.6289 28.0527C12.6289 38.5186 21.8513 46.6855 32.8145 46.6855C35.7561 46.6855 38.6871 46.0861 41.3405 44.9467L50.5365 49.6224C51.0946 49.9061 51.7687 49.8269 52.2458 49.4215C52.7229 49.016 52.9098 48.3636 52.7198 47.7671L49.696 38.274ZM26.5 29.6055C25.6425 29.6055 24.9473 28.9103 24.9473 28.0527C24.9473 27.1952 25.6425 26.5 26.5 26.5C27.3575 26.5 28.0527 27.1952 28.0527 28.0527C28.0527 28.9103 27.3575 29.6055 26.5 29.6055ZM32.7109 29.6055C31.8534 29.6055 31.1582 28.9103 31.1582 28.0527C31.1582 27.1952 31.8534 26.5 32.7109 26.5C33.5685 26.5 34.2637 27.1952 34.2637 28.0527C34.2637 28.9103 33.5685 29.6055 32.7109 29.6055ZM38.9219 29.6055C38.0644 29.6055 37.3691 28.9103 37.3691 28.0527C37.3691 27.1952 38.0644 26.5 38.9219 26.5C39.7794 26.5 40.4746 27.1952 40.4746 28.0527C40.4746 28.9103 39.7794 29.6055 38.9219 29.6055Z" fill="#727272" />
                        </svg>
                      </div>
                      <div className="contact-num">
                        <span>Chat Us Anytime</span>
                        <h5><Link to="tel:4065550120">051-9450053</Link></h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>


    </>
  )
}
