import React, { useState } from 'react'
import './hero.css'
import { useTypewriter, Cursor } from 'react-simple-typewriter';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useToastNotification from '../Toast/useToastNotification';

const Hero = () => {
    const [text1] = useTypewriter({
        words: [
            'DO YOU KNOW RCIC’S ARE ONLY AUTHORISED PROFESSIONALS TO REPRESENT YOU FOR IMMIGRATION AND CITIZENSHIP',
            'GET SERVICES FROM REGULATED CANADIAN IMMIGRATION CONSULTANT',
            'TRUST IMMIGRA CONSULTANTS PRIVATE LIMITED AND OUR AUTHORISED IRCC’S CONSULTANTS'
        ],
        loop: 0,
        typeSpeed: 50,
        deleteSpeed: 50,
    });

    const { showSuccessToast, showErrorToast } = useToastNotification();
    const [text, setText] = useState('Begin Assessment');
    const onSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        let allFieldsFilled = true;
        for (let [key, value] of formData.entries()) {
            if (!value.trim()) {
                allFieldsFilled = false;
                break;
            }
        }

        if (!allFieldsFilled) {
            showErrorToast("Please fill all fields.");
            return;
        }

        setText("Sending..");
        formData.append("access_key", "336c68a0-b877-41d3-acf9-ab97b3416c12");

        try {
            const response = await fetch("https://api.web3forms.com/submit", {
                method: "POST",
                body: formData
            });

            const data = await response.json();
            if (data.success) {
                showSuccessToast(data.message);
                event.target.reset();
            } else {
                showErrorToast(`Error: ${data.message}`);
            }
        } catch (error) {
            showErrorToast("An unexpected error occurred.");
        } finally {
            setText('Begin Assessment');
        }
    };


    return (
        <div className="container-fluid bg-image">
            <div className="hero-section">
                <div className="left">
                    <p className='left-para'>
                        {text1}
                        <Cursor />
                    </p>
                    <button className='btn1'>
                        Read More <img src='assets/imgs/right arrow.png' alt="right-arrow" />
                    </button>
                </div>
                <div className="right">
                    <div className="form-container">
                        <p className="form-header">Discover your Eligibility For Canadian Immigration with A 30 min Interview.
                        </p>
                        <form onSubmit={onSubmit}>
                            <div className="form-group1">
                                <input type="text" id="input1" name='name' className="form-control1" placeholder="Full Name" />
                            </div>
                            <div className="form-group1">
                                <input type="text" id="input2" name='email' className="form-control1" placeholder="Email" />
                            </div>
                            <div className="form-group1">
                                <input type="text" id="input3" name='number' className="form-control1" placeholder="Number" />
                            </div>
                            <div className="form-group1">
                                <select name="services" id="" className='form-control2'>
                                    <option value="">Select Service</option>
                                    <option value="GRE Exam">GRE Exam</option>
                                    <option value="IELTS Exam">IELTS Exam</option>
                                    <option value="Student Visa">Student Visa</option>
                                    <option value="Family Visa">Family Visa</option>
                                </select>
                            </div>
                            <button type="submit" className="btn2">{text}</button>
                        </form>
                        <p className="form-footer">By clicking the above button, you consent to receiving a call and email from Immigra..</p>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default Hero;
