import React, { useEffect } from 'react'
import './foreignwork.css'
import GetStarted from '../../GetStarted/GetStarted';
import { Link } from 'react-router-dom';
import WhyImagria from '../../Why Imagria/WhyImagria';
const ForeignWork = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const cards = [
        {
            image: "assets/imgs/step-to-follow/1.png",
            title: "Personalised Consultation",
            description: "Our journey begins with a personalized consultation session, where we understand your career goals, skills, and aspirations. We assess your eligibility for a work permit and strategize the best course of action to achieve your Canadian employment objectives.",
            num: "01"
        },
        {
            image: "assets/imgs/step-to-follow/2.png",
            title: "Job Offer Assistance",
            description: "Securing a job offer is a crucial step in the work permit application process. We collaborate with reputable Canadian employers through our certified consultants and provide assistance in obtaining a job offer that aligns with your skills and qualifications.",
            num: "02"
        },
        {
            image: "assets/imgs/step-to-follow/3.png",
            title: "Documentation Guidance",
            description: "Navigating the documentation requirements can be overwhelming. Our expert team meticulously reviews and assists you in preparing all necessary documents, including job offer letters, educational qualifications, police clearance certificates, and medical examination reports.",
            num: "03"
        },
        {
            image: "assets/imgs/step-to-follow/file.png",
            title: "Application Submission",
            description: "We streamline the application submission process by leveraging our extensive experience and expertise. Our team of Regulated Immigration Consultants ensures that your application is accurately completed and submitted within stipulated timelines, minimizing delays and maximizing your chances of approval.",
            num: "04"
        },
        {
            image: "assets/imgs/step-to-follow/thumbs_impression.png",
            title: "Biometrics Appointment Support",
            description: "If biometrics are required as part of your application, we facilitate the scheduling of appointments at designated Visa Application Centres (VACs) and provide guidance on the biometric data collection process.",
            num: "05"
        },
        {
            image: "assets/imgs/step-to-follow/hand.png",
            title: "Ongoing Support and Communication",
            description: "Throughout the application process, we maintain open communication and provide regular updates on the status of your application. Our dedicated team remains available to address any queries or concerns you may have, ensuring a seamless experience from start to finish.",
            num: "06"
        }
    ];
    return (
        <div className="page-wrapper">
            <div className="breadcrumb__area2 bg-color-9 p-relative" data-background="assets/imgs/page-title/pt-shape.jpg">
                <div className="shape-bg" data-background="assets/imgs/page-title/pt-shape.jpg" />
                <div className="shape-1" data-background="assets/imgs/page-title/pt-shape.jpg" />
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb__wrapper p-relative bd-crum">
                                <div className="breadcrumb__menu">
                                    <nav>
                                        <ul>
                                            <li><span><Link href="index.html">Home</Link></span></li>
                                            <li><span>Foreign Workers</span></li>
                                        </ul>
                                    </nav>
                                </div>
                                <h2 className="breadcrumb__title">Foreign Workers</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="consulting-firm-section section-space-top section-space-medium-bottom p-relative">
                <div className="shape-1">
                    <img src="assets/imgs/resources/shape-glob.png" alt="shape" />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="p-relative image-11">
                                <img src="assets/imgs/resources/cf-1.png" alt="consulting-image" className='image-55' />
                                <div className="wptb-radial-progress text-center">
                                    <h6>Success Rate</h6>
                                    <div className="wptb-item--inner">
                                        <div className="wptb-item--image">
                                            <svg className="radial-progress" data-countervalue={95} viewBox="0 0 80 80">
                                                <circle className="bar-static" cx={40} cy={40} r={35} />
                                                <circle className="bar--animated" cx={40} cy={40} r={35} style={{ strokeDashoffset: '217.8' }} />
                                                <text className="countervalue start" x="50%" y="54%" transform="matrix(0, 1, -1, 0, 80, 0)">95</text>
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="flags p-relative">
                                        <img className="three" src="assets/imgs/resources/flag-3.png" alt="flag" />
                                        <span>Canada</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="con-firm-content p-relative">
                                <div className="title-box wow mb-40 fadeInDown p-relative" data-wow-delay="500ms">
                                    <h5 className="section-sub-title"><span>|</span> About Us <span>|</span></h5>
                                    <h2 className="section-title">WORK PERMIT VISA SERVICES<br />FOR CANADA</h2>
                                    <p>At Immigra Consultant Private Limited, our team of Regulated Canadian Immigration Consultants is here to guide you through the process every step of the way. Here's how to obtain your Residency Permit:</p>
                                </div>
                                <Link to="/" className="primary-btn-1 btn-hover" href="about.html">
                                    Get Started
                                    <span style={{ top: '147.172px', left: '108.5px' }} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="StepToFlowSection">
                <div className="myContainer mx-auto py-5 px-0">
                    <h6>steps</h6>
                    <h1 className="text-center text-capitalize" style={{ fontWeight: "bold" }}>step to follow</h1>
                    <div className="cards-container">
                        {cards.map(v => (
                            <div key={v.title} className="InternationalStudents d-flex  align-items-center  justify-content-between   flex-column ">
                                <figure><img src={v.image} alt={v.title} /></figure>
                                <div className='content d-flex align-items-center justify-content-center flex-column'>
                                    <h1 className='num'>{v.num}</h1>
                                    <h1 className='title'>{v.title}</h1>
                                    <p className='description'>{v.description}</p>
                                    <i></i>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <WhyImagria />
            <GetStarted />
        </div>
    )
}

export default ForeignWork
