import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import './international_students.css'
import GetStarted from '../../GetStarted/GetStarted';
const InternationalStudents = () => {
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])
  const cards = [
    {
      image: "assets/imgs/step-to-follow/1.png",
      title: "Acceptance to a Designated Learning Institution",
      description: "Research and Choose a Program: Explore Canadian Universities, Colleges, or other Designated Learning Institutions (DLIs) offering programs that align with your academic and career goals. We'll assist you in applying for admission and acceptance",
      num: "01"
    },
    {
      image: "assets/imgs/step-to-follow/2.png",
      title: "Gather Required Documents",
      description: "Depending on your specific Circumstances/Requirements, you may need to provide all relevant documents.",
      num: "02"
    },
    {
      image: "assets/imgs/step-to-follow/3.png",
      title: "Apply for a Study Permit through IRCC",
      description: "Expert Guidance: Our team of Regulated Canadian Immigration Consultants will assist you in applying through the Immigration, Refugees and Citizenship Canada (IRCC) platform. We'll handle the submission process and help you complete your biometric registration through the Visa Application Centre (VAC)",
      num: "03"
    },
    {
      image: "assets/imgs/step-to-follow/file.png",
      title: "Wait for Processing until Decision is Received",
      description: "Timely Updates: Processing times vary, but we'll keep you informed throughout the process. Once a decision is made on your study permit application, you'll receive a Port of Entry (POE) Letter of Introduction.",
      num: "04"
    },
    {
      image: "assets/imgs/step-to-follow/thumbs_impression.png",
      title: "Travel to Canada",
      description: "Travel Planning Assistance: If your study permit is approved, we'll help you plan your travel to Canada. Upon arrival, present your passport, Port of Entry Letter of Introduction, and other relevant documents to the immigration officer at the Canadian port of entry.",
      num: "05"
    },
    {
      image: "assets/imgs/step-to-follow/hand.png",
      title: "Study in Canada",
      description: "Guidance and Support: Once in Canada, abide by the conditions of your study permit. We'll provide guidance on maintaining full-time enrollment at a DLI and complying with any work or other restrictions specified in your permit",
      num: "06"
    }
  ];
  return (
    <div className="page-wrapper">
      <div className="breadcrumb__area bg-color-9 p-relative" data-background="assets/imgs/page-title/pt-shape.jpg">
        <div className="shape-bg" data-background="assets/imgs/page-title/pt-shape.jpg" />
        <div className="shape-1" data-background="assets/imgs/page-title/pt-shape.jpg" />
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumb__wrapper p-relative ">
                <div className="breadcrumb__menu">
                  <nav>
                    <ul>
                      <li><span><a href="index.html">Home</a></span></li>
                      <li><span>International Students</span></li>
                    </ul>
                  </nav>
                </div>
                <h2 className="breadcrumb__title">International Students</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="consulting-firm-section section-space-top section-space-medium-bottom p-relative">
        <div className="shape-1">
          <img src="assets/imgs/resources/shape-glob.png" alt="shape" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="p-relative image-11">
                <img src="assets/imgs/resources/cf-1.png" alt="consulting-image" className='image-55' />
                <div className="wptb-radial-progress text-center">
                  <h6>Success Rate</h6>
                  <div className="wptb-item--inner">
                    <div className="wptb-item--image">
                      <svg className="radial-progress" data-countervalue={95} viewBox="0 0 80 80">
                        <circle className="bar-static" cx={40} cy={40} r={35} />
                        <circle className="bar--animated" cx={40} cy={40} r={35} style={{ strokeDashoffset: '217.8' }} />
                        <text className="countervalue start" x="50%" y="54%" transform="matrix(0, 1, -1, 0, 80, 0)">95</text>
                      </svg>
                    </div>
                  </div>
                  <div className="flags p-relative">
                    <img className="three" src="assets/imgs/resources/flag-3.png" alt="flag" />
                    <span>Canada</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="con-firm-content p-relative">
                <div className="title-box wow mb-40 fadeInDown p-relative" data-wow-delay="500ms">
                  <h5 className="section-sub-title"><span>|</span> About Us <span>|</span></h5>
                  <h2 className="section-title">EXPLORING STUDY PERMITS<br />FOR CANADA</h2>
                  <p>Are you considering studying in Canada?
                    Your aspirations can become reality! A Study Permit, commonly referred to as a “Student Visa,” is a prerequisite for studying in Canada.
                    Our responsibility is to assist you in finding the optimal solution and guiding you through the immigration process for the best outcome.</p>
                </div>
                <Link to="/" className="primary-btn-1 btn-hover" href="about.html">
                  Get Started
                  <span style={{ top: '147.172px', left: '108.5px' }} />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="StepToFlowSection">
        <div className="myContainer mx-auto py-5 px-0">
          <h6>steps</h6>
          <h1 className="text-center text-capitalize" style={{ fontWeight: "bold" }}>step to follow</h1>
          <div className="cards-container">
            {cards.map(v => (
              <div key={v.title} className="InternationalStudents d-flex  align-items-center  justify-content-between   flex-column ">
                <figure><img src={v.image} alt={v.title} /></figure>
                <div className='content d-flex align-items-center justify-content-center flex-column'>
                  <h1 className='num'>{v.num}</h1>
                  <h1 className='title'>{v.title}</h1>
                  <p className='description'>{v.description}</p>
                  <i></i>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <GetStarted/>

    </div>

  )
}

export default InternationalStudents