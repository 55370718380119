import React from 'react'
import "./whyimagria.css"
const WhyImagria = () => {
    const cards = [
        {
            title: "Expertise",
            description: "Our team of experienced immigration consultants possesses in-depth knowledge of Canadian immigration policies and procedures.",

        },
        {
            title: "Personalised Service",
            description: "We understand that each client is unique, and we tailor our services to meet your specific needs and objectives",

        },
        {
            title: "Efficiency",
            description: "With our streamlined approach, we expedite the application process and minimize unnecessary delays.",

        },
        {
            title: "Transparency",
            description: "We believe in transparency and keep you informed at every stage of the process, ensuring peace of mind throughout your journey.",

        }
    ];
    return (
        <section id="StepToFlowSection">


            <div className="myContainer mx-auto py-5 px-0">

                <h6>why Us</h6>

                <h1 className="text-center text-capitalize" style={{ fontWeight: "bold" }}>WHY CHOOSE IMMIGRA CONSULTANTS<br />PRIVATE LIMITED</h1>

                <div className="d-flex align-items-center justify-content-center flex-wrap gap-3 mt-2">

                    {cards.map(v => <div key={v.title} className="d-flex  align-items-center  justify-content-between   flex-column  StepToFlowSection-card22">


                        <div className='d-flex align-items-center justify-content-center flex-column '>
                            <h1 className='text-capitalize text-center num'>{v.title}</h1>
                            <p className='text-capitalize  text-center'>{v.description}</p>
                            <i></i>
                        </div>

                    </div>)}

                </div>

            </div>


        </section>
    )
}

export default WhyImagria
