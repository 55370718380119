import React, { useEffect } from 'react'
import Swiper from 'swiper/bundle';
const Testimonials = () => {
    useEffect(() => {
        const testimonial = new Swiper('.testimonial-1-active', {
            slidesPerView: 2,
            spaceBetween: 20,
            loop: true,
            roundLengths: true,
            autoplay: {
                delay: 1000,
            },
            pagination: {
                el: ".testimonial-1-swiper-dot",
                clickable: true,
            },
            navigation: {
                nextEl: ".testimonial-1-button-next",
                prevEl: ".testimonial-1-button-prev",
            },
            breakpoints: {
                '1400': {
                    slidesPerView: 2,
                },
                '1200': {
                    slidesPerView: 2,
                },
                '992': {
                    slidesPerView: 1,
                },
                '768': {
                    slidesPerView: 1,
                },
                '576': {
                    slidesPerView: 1,
                },
                '0': {
                    slidesPerView: 1,
                },
            },
            speed: 3000,

        });
    })
    return (
        <div className="page-wrapper">
            <section className="testimonials-section p-relative">
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-lg-6">
                            <figure className="image w-img pr-65">
                                <img src="assets/imgs/resources/testimonials.png" alt="image" />
                            </figure>
                        </div>
                        <div className="col-lg-6">
                            <div className="testimonials-slide-area pl-65">
                                <div className="title-box wow mb-45 fadeInDown p-relative" data-wow-delay="500ms">
                                    <h5 className="section-sub-title"><span>|</span> Testimonials <span>|</span></h5>
                                    <h2 className="section-title">What Our Clients Say About<br /> Immigrat</h2>
                                </div>
                                <div className="swiper testimonial-1-active">
                                    <div className="swiper-wrapper">
                                        <div className="swiper-slide">
                                            <div className="testimonials-box">
                                                <div className="image-box">
                                                    <div className="image-holder p-relative">
                                                        <img src="assets/imgs/resources/generic-avatar.png" alt="author" />
                                                    </div>
                                                    <div className="content">
                                                        <h5>Wade Warren</h5>
                                                        <span>Customers</span>
                                                        <ul>
                                                            <li><i className="fas fa-square-star" /></li>
                                                            <li><i className="fas fa-square-star" /></li>
                                                            <li><i className="fas fa-square-star" /></li>
                                                            <li><i className="fas fa-square-star" /></li>
                                                            <li><i className="fas fa-square-star" /></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <p>I would like to express my gratitude to Immigrar Canada for their valuable assistance throughout my immigration journey. Their in-depth expertise, availability and patience were essential for me to realize my life plan in Quebec.</p>
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="testimonials-box">
                                                <div className="image-box">
                                                    <div className="image-holder p-relative">
                                                        <img src="assets/imgs/resources/generic-avatar.png" alt="author" />
                                                    </div>
                                                    <div className="content">
                                                        <h5>Henry Marn</h5>
                                                        <span>Customers</span>
                                                        <ul>
                                                            <li><i className="fas fa-square-star" /></li>
                                                            <li><i className="fas fa-square-star" /></li>
                                                            <li><i className="fas fa-square-star" /></li>
                                                            <li><i className="fas fa-square-star" /></li>
                                                            <li><i className="fas fa-square-star" /></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <p>I would like to express my gratitude to Immigrar Canada for their valuable assistance throughout my immigration journey. Their in-depth expertise, availability and patience were essential for me to realize my life plan in Quebec.</p>
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="testimonials-box">
                                                <div className="image-box">
                                                    <div className="image-holder p-relative">
                                                        <img src="assets/imgs/resources/generic-avatar.png" alt="author" />
                                                    </div>
                                                    <div className="content">
                                                        <h5>Wade Warren</h5>
                                                        <span>Customers</span>
                                                        <ul>
                                                            <li><i className="fas fa-square-star" /></li>
                                                            <li><i className="fas fa-square-star" /></li>
                                                            <li><i className="fas fa-square-star" /></li>
                                                            <li><i className="fas fa-square-star" /></li>
                                                            <li><i className="fas fa-square-star" /></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <p>I would like to express my gratitude to Immigrar Canada for their valuable assistance throughout my immigration journey. Their in-depth expertise, availability and patience were essential for me to realize my life plan in Quebec.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Testimonials
