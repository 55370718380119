import React from 'react'
import './getstarted.css'
const GetStarted = () => {
    return (
        <div>
            <div className="breadcrumb__area1 bg-color-9 p-relative" data-background="assets/imgs/page-title/pt-shape.jpg">
                <div className="shape-bg" data-background="assets/imgs/page-title/pt-shape.jpg" />
                <div className="shape-1" data-background="assets/imgs/page-title/pt-shape.jpg" />
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb__wrapper1 p-relative">
                                <div className="breadcrumb__menu1">
                                    <p>Get Started Today</p>
                                    <p className="breadcrumb__title1">Embark on your journey to working in Canada with confidence.
                                        Contact us today to schedule your personalized consultation and take the first step towards realizing your Canadian employment dreams.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GetStarted
