import React, { useEffect } from 'react'
import './permanentresidence.css'
import { Link } from 'react-router-dom';
import GetStarted from '../GetStarted/GetStarted';

const PermanentResidence = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const cards = [
        {
            image: "assets/imgs/step-to-follow/1.png",
            title: "Initial Consultation",
            description: "Start by scheduling a personalized consultation with our experienced consultants. During this session, we'll assess your eligibility for various residency programs in Canada and discuss your immigration goals and preferences..",
            num: "01"
        },
        {
            image: "assets/imgs/step-to-follow/badge.png",
            title: "Determine Eligibility",
            description: "Based on your qualifications, work experience, language proficiency, and other factors, we'll help you determine the most suitable residency program for your immigration journey. Whether you're interested in Express Entry, Provincial Nominee Programs (PNPs), Family Sponsorship, or other pathways, we'll tailor our guidance to meet your needs.",
            num: "02"
        },
        {
            image: "assets/imgs/step-to-follow/tick.png",
            title: "Choose the Right Program",
            description: "With our expert advice, choose the residency program that aligns best with your goals and circumstances. We'll provide detailed information about each program, including eligibility criteria, application process, and expected outcomes, to help you make an informed decision.",
            num: "03"
        },
        {
            image: "assets/imgs/step-to-follow/file.png",
            title: "Gather Required Docs",
            description: "Our team will assist you in compiling all necessary documents for your residency permit application. From identification documents and educational certificates to language test results and proof of funds, we'll ensure that your application package is complete and meets the requirements of the chosen immigration program.",
            num: "04"
        },
        {
            image: "assets/imgs/step-to-follow/group.png",
            title: "Application Submission",
            description: "Once your application package is ready, we'll submit it to the appropriate immigration authorities on your behalf. Our consultants will ensure that your application is accurate, well-prepared, and submitted within the specified timelines, minimizing the risk of delays or rejections.",
            num: "05"
        },
        {
            image: "assets/imgs/step-to-follow/group1.png",
            title: "Application Processing",
            description: "Throughout the processing period, we'll keep you informed about the status of your application and any updates or requests for additional information from immigration authorities. Our team will proactively address any concerns or inquiries to ensure a smooth and efficient processing experience.",
            num: "06"
        },
        {
            image: "assets/imgs/step-to-follow/file1.png",
            title: "Receive Decision",
            description: "Upon receiving a decision on your residency permit application, we'll notify you immediately. If your application is approved, we'll guide you through the next steps, including obtaining your permanent resident status documentation and preparing for your new life in Canada.",
            num: "05"
        },
        {
            image: "assets/imgs/step-to-follow/approved.png",
            title: "Post-Approval Support",
            description: "Even after your residency permit is approved, our support continues. We'll assist you with any additional requirements, such as landing procedures, obtaining social insurance numbers, and accessing settlement services, to ensure a successful transition to permanent residency in Canada.",
            num: "06"
        }
    ];
    return (
        <div className="page-wrapper">
            <div className="breadcrumb__area3 bg-color-9 p-relative" data-background="assets/imgs/page-title/pt-shape.jpg">
                <div className="shape-bg" data-background="assets/imgs/page-title/pt-shape.jpg" />
                <div className="shape-1" data-background="assets/imgs/page-title/pt-shape.jpg" />
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb__wrapper p-relative bd-crum">
                                <div className="breadcrumb__menu">
                                    <nav>
                                        <ul>
                                            <li><span><Link href="index.html">Home</Link></span></li>
                                            <li><span>Permanent Residence</span></li>
                                        </ul>
                                    </nav>
                                </div>
                                <h2 className="breadcrumb__title">Permanent Residence</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="consulting-firm-section section-space-top section-space-medium-bottom p-relative">
                <div className="shape-1">
                    <img src="assets/imgs/resources/shape-glob.png" alt="shape" />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="p-relative image-11">
                                <img src="assets/imgs/resources/cf-1.png" alt="consulting-image" className='image-55' />
                                <div className="wptb-radial-progress text-center">
                                    <h6>Success Rate</h6>
                                    <div className="wptb-item--inner">
                                        <div className="wptb-item--image">
                                            <svg className="radial-progress" data-countervalue={95} viewBox="0 0 80 80">
                                                <circle className="bar-static" cx={40} cy={40} r={35} />
                                                <circle className="bar--animated" cx={40} cy={40} r={35} style={{ strokeDashoffset: '217.8' }} />
                                                <text className="countervalue start" x="50%" y="54%" transform="matrix(0, 1, -1, 0, 80, 0)">95</text>
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="flags p-relative">
                                        <img className="three" src="assets/imgs/resources/flag-3.png" alt="flag" />
                                        <span>Canada</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="con-firm-content p-relative">
                                <div className="title-box wow mb-40 fadeInDown p-relative" data-wow-delay="500ms">
                                    <h5 className="section-sub-title"><span>|</span> About Us <span>|</span></h5>
                                    <h2 className="section-title">WORK PERMIT VISA SERVICES<br />FOR CANADA</h2>
                                    <p>At Immigra Consultant Private Limited, our team of Regulated Canadian Immigration Consultants is here to guide you through the process every step of the way. Here's how to obtain your Residency Permit:</p>
                                </div>
                                <Link to="/" className="primary-btn-1 btn-hover" href="about.html">
                                    Get Started
                                    <span style={{ top: '147.172px', left: '108.5px' }} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="StepToFlowSection">
                <div className="myContainer mx-auto py-5 px-0">
                    <h6>steps</h6>
                    <h1 className="text-center text-capitalize" style={{ fontWeight: "bold" }}>step to follow</h1>
                    <div className="cards-container1">
                        {cards.map(v => (
                            <div key={v.title} className="InternationalStudents11 d-flex  align-items-center  justify-content-between   flex-column ">
                                <figure><img src={v.image} alt={v.title} /></figure>
                                <div className='content d-flex align-items-center justify-content-center flex-column'>
                                    <h1 className='num'>{v.num}</h1>
                                    <h1 className='title'>{v.title}</h1>
                                    <p className='description'>{v.description}</p>
                                    <i></i>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <GetStarted />
        </div>
    )
}

export default PermanentResidence
