import React from 'react'
import { Link } from 'react-router-dom'
import './fourofour.css'
const FourOFour = () => {
    return (
        <div class="page_404">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="col-sm-12 col-sm-offset-1 text-center">
                            <div class="four_zero_four_bg">
                                <h1 class="heading-1">404</h1>
                            </div>
                            <div class="content_box_404">
                                <h3 class="h2">Looks Like You're Lost</h3>
                                <p className='para'>The page you are looking for not available</p>
                                <Link className='link' to="/">Go to Home</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FourOFour
