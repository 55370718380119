import { toast } from 'react-toastify';
const useToastNotification = () => {
    const showSuccessToast = (e) => {
        toast.success(e, {
            autoClose: 2000,
        });
    };

    const showErrorToast = (e) => {
        toast.error(e, {
            autoClose: 2000,
        });
    };

    return { showSuccessToast, showErrorToast };
};

export default useToastNotification;
